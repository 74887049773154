import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"; // Import Firestore

const StudentQuizTakingPage = () => {
  const location = useLocation();
  const [quiz, setQuiz] = useState(null);
  const [currentLevel, setCurrentLevel] = useState("easy");
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [retakeMode, setRetakeMode] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);
  const [answers, setAnswers] = useState({});
  const [studentPoints, setStudentPoints] = useState(0);
  const [totalPointsPossible, setTotalPointsPossible] = useState(0);
  const usedQuestionIdsRef = useRef(new Set()); // New ref to track all previously used questions

  const db = getFirestore();
  const retakeQuestionsRef = useRef([]);
  const correctAnswersRef = useRef(0);
  const totalQuestionsRef = useRef(0);
  const cumulativeCorrectAnswersRef = useRef(0);
  const cumulativeTotalQuestionsRef = useRef(0);
  const cumulativeQuestionNumberRef = useRef(0);

  useEffect(() => {
    if (location.state?.quiz) {
      setQuiz(location.state.quiz);
      initializeLevel("easy", location.state.quiz);

      const totalPoints = location.state.quiz.Questions.reduce(
        (total, q) => total + (q.Points || 0),
        0
      );
      setTotalPointsPossible(totalPoints);

      // Set total number of questions from all questions once
      cumulativeTotalQuestionsRef.current = location.state.quiz.Questions.length;
    }
  }, [location.state]);

  const initializeLevel = (level, quizData) => {
    const levelQuestions = quizData.Questions.filter(
      (q) => q.Difficulty_Level.toLowerCase() === level
    );
    setCurrentQuestions(levelQuestions);
    setCurrentQuestionIndex(0);
    setAnswers({});
    setTimeLeft(parseTimer(levelQuestions[0]?.Timer) || 15);
    retakeQuestionsRef.current = [];
    correctAnswersRef.current = 0; // Reset correct answers for new level
    totalQuestionsRef.current = levelQuestions.length;
    setRetakeMode(false);
  };

  const parseTimer = (timerString) => {
    if (!timerString) return 15;
    const parts = timerString.split(" ");
    const timerValue = parseInt(parts[0], 10);
    const timeUnit = parts[1].toLowerCase();

    return timeUnit.startsWith("min") ? timerValue * 60 : timerValue;
  };

  useEffect(() => {
    if (timeLeft === null) return;
    if (timeLeft <= 0) {
      handleUnattempted();
      moveToNextQuestion();
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  useEffect(() => {
    if (currentQuestions.length > 0 && currentQuestions[currentQuestionIndex]) {
      const timerInSeconds = parseTimer(currentQuestions[currentQuestionIndex].Timer);
      setTimeLeft(timerInSeconds);
    }
  }, [currentQuestionIndex, currentQuestions]);

  const resetAnswer = (question) => {
    switch (question.Question_Type) {
      case "True or False":
      case "Yes or No":
        return null;
      case "Identification":
        return "";
      case "Multiple Choice":
        return [];
      default:
        return null;
    }
  };

  useEffect(() => {
    if (currentQuestions.length > 0 && currentQuestions[currentQuestionIndex]) {
      const currentQuestion = currentQuestions[currentQuestionIndex];
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestion.id]: resetAnswer(currentQuestion),
      }));
    }
  }, [currentQuestionIndex, currentQuestions]);

  const handleUnattempted = () => {
    alert("Time's up! Question marked as unattempted.");
    const currentQuestion = currentQuestions[currentQuestionIndex];
    if (currentQuestion && !retakeMode) {
      retakeQuestionsRef.current.push(currentQuestion);
    }
  };

  const moveToNextQuestion = () => {
    if (!retakeMode) {
      cumulativeQuestionNumberRef.current += 1;
    }

    if (currentQuestionIndex < currentQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleQuizEnd();
    }
  };

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleIdentificationChange = (answer) => {
    const currentQuestion = currentQuestions[currentQuestionIndex];
    if (currentQuestion) {
      handleAnswerChange(currentQuestion.id, answer);
    }
  };

  const handleSubmitQuiz = () => {
    const currentQuestion = currentQuestions[currentQuestionIndex];
    if (!currentQuestion) return;
  
    const correct = checkAnswer(currentQuestion);
  
    if (currentQuestion.id) {
      usedQuestionIdsRef.current.add(currentQuestion.id); // Only add if the ID is defined
    }
  
    if (correct) {
      correctAnswersRef.current += 1;
      cumulativeCorrectAnswersRef.current += 1;
      setStudentPoints((prevPoints) => prevPoints + (currentQuestion.Points || 0));

    } else {
   
      if (!retakeMode) {
        retakeQuestionsRef.current.push(currentQuestion);
      } else {
        retakeQuestionsRef.current.push(currentQuestion);
      }
    }
  
    moveToNextQuestion();
  };
  

  const checkAnswer = (question) => {
    const studentAnswer = answers[question.id];
    if (
      studentAnswer === null ||
      studentAnswer === undefined ||
      (typeof studentAnswer === "string" && studentAnswer.trim() === "") ||
      (Array.isArray(studentAnswer) && studentAnswer.length === 0)
    ) {
      return false;
    }

    if (question.Question_Type === "Multiple Choice") {
      const correctChoices = question.Choices.filter((choice) => choice.Is_Correct).map((c) => c.Choice_Text);
      return (
        Array.isArray(studentAnswer) &&
        correctChoices.length === studentAnswer.length &&
        correctChoices.every((choice) => studentAnswer.includes(choice))
      );
    } else if (
      question.Question_Type === "True or False" ||
      question.Question_Type === "Yes or No"
    ) {
      return question.Choices.some(
        (choice) => choice.Is_Correct && studentAnswer === choice.Choice_Text
      );
    } else if (question.Question_Type === "Identification") {
      // Get the correct answer from question.Choices[0].Choice_Text
      const correctAnswer = question.Choices.find(choice => choice.Is_Correct)?.Choice_Text;
      return correctAnswer?.toLowerCase().trim() === studentAnswer?.toLowerCase().trim();
    }
    return false;
  };

  const handleQuizEnd = () => {
    const level = currentLevel;
    const correct = correctAnswersRef.current;
    const total = totalQuestionsRef.current;
    const requiredAccuracy = quiz.Accuracy[level];
    const currentAccuracy = total > 0 ? (correct / total) * 100 : 0;
  
    if (currentAccuracy >= requiredAccuracy) {
      alert(`Congratulations! You've met the threshold requirement for ${level}.`);
      proceedToNextLevel();
    } else {
      alert(`You did not meet the required threshold for ${level}. You will proceed to re-take new questions.`);
      setRetakeMode(true);
      initializeRetake();
    }
  };
  

  const initializeRetake = async () => {
    const numberOfIncorrect = retakeQuestionsRef.current.length;
  
    // Use `currentLevel` to pass the correct difficulty level
    const newQuestions = await fetchNewQuestions(currentLevel, numberOfIncorrect);
  
    if (newQuestions.length > 0) {
      setCurrentQuestions(newQuestions);
      setCurrentQuestionIndex(0);
      setTimeLeft(parseTimer(newQuestions[0]?.Timer) || 15);
      retakeQuestionsRef.current = [];
      totalQuestionsRef.current = newQuestions.length;
    } else {
      alert("No new questions are available for retake. Retaking with the available incorrect questions.");
      setCurrentQuestions([...retakeQuestionsRef.current]);
      setCurrentQuestionIndex(0);
      setTimeLeft(parseTimer(retakeQuestionsRef.current[0]?.Timer) || 15);
    }
  };
  

  const fetchNewQuestions = async (difficultyLevel, numberOfQuestions) => {
    try {
      let questionBankId = quiz?.Question_Bank_ID || "";
      console.log("Difficulty Level:", difficultyLevel);
      console.log("Question Bank ID:", questionBankId);
  
      if (!questionBankId) {
        console.error("Question Bank ID not provided.");
        return [];
      }
  
      // Step 1: Fetch questions from tbl_question using Question_Bank_ID and Difficulty_Level
      const questionsCollection = collection(db, "tbl_question");
      const newQuestionsQuery = query(
        questionsCollection,
        where("Question_Bank_ID", "==", questionBankId),
        where("Difficulty_Level", "==", difficultyLevel.charAt(0).toUpperCase() + difficultyLevel.slice(1))
      );
  
      const querySnapshot = await getDocs(newQuestionsQuery);
      let allQuestions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      if (querySnapshot.empty) {
        console.warn("No questions found for the given difficulty level and question bank.");
      } else {
        console.log("Questions fetched from Firestore:");
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
        });
      }
  
      const previouslyUsedIds = Array.from(usedQuestionIdsRef.current).filter((id) => id !== undefined);
      console.log("Previously used question IDs:", previouslyUsedIds);
      allQuestions = allQuestions.filter((q) => !previouslyUsedIds.includes(q.id));
      console.log("Filtered new questions (after excluding used ones):", allQuestions);
  
      // Step 2: Fetch choices for each question from tbl_choices and tbl_answers
      for (let question of allQuestions) {
        const choicesCollection = collection(db, "tbl_choices");
        const choicesQuery = query(
          choicesCollection,
          where("Question_ID", "==", question.id)
        );
  
        const choicesSnapshot = await getDocs(choicesQuery);
        const choices = choicesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // Attach choices to the respective question
        question.Choices = choices;
        console.log("Choices fetched for question:", question.id, choices);
  
        // Fetch answer if the question type is Identification
        if (question.Question_Type === "Identification") {
          const answersCollection = collection(db, "tbl_answers");
          const answersQuery = query(
            answersCollection,
            where("Question_ID", "==", question.id)
          );
  
          const answersSnapshot = await getDocs(answersQuery);
          const answers = answersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
  
          // Attach the answer to the respective question (assumes there is only one correct answer)
          if (answers.length > 0) {
            question.Answer = answers[0].Answer_text;
          }
          console.log("Answer fetched for question:", question.id, answers);
        }
      }
  
      // Step 3: Randomly select the requested number of questions from all questions
      allQuestions = allQuestions.sort(() => 0.5 - Math.random()).slice(0, numberOfQuestions);
      console.log("Randomly picked questions:", allQuestions);
  
      return allQuestions;
    } catch (error) {
      console.error("Error fetching new questions:", error);
      return [];
    }
  };

  const fisherYatesShuffle = (array) => {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };
  

  const proceedToNextLevel = () => {
    if (currentLevel === "easy") {
      setCurrentLevel("medium");
      initializeLevel("medium", quiz);
    } else if (currentLevel === "medium") {
      setCurrentLevel("hard");
      initializeLevel("hard", quiz);
    } else {
      handleShowResults();
    }
  };

  const handleShowResults = () => {
    const totalCorrect = cumulativeCorrectAnswersRef.current;
    const totalQuestions = cumulativeTotalQuestionsRef.current;
    const accuracy = totalQuestions > 0 ? ((studentPoints / totalPointsPossible) * 100).toFixed(2) : 0;

    alert(`Quiz Completed!\n\nTotal Questions: ${totalQuestions}\nCorrect Answers: ${totalCorrect}\nAccuracy: ${accuracy}%\n\nYour Score: ${studentPoints}/${totalPointsPossible}`);
  };

  const getDifficultyClass = (difficulty) => {
    switch (difficulty.toLowerCase()) {
      case "easy":
        return "bg-custom-brownbg";
      case "medium":
        return "bg-custom-brownbg";
      case "hard":
        return "bg-custom-brownbg";
      default:
        return "";
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  if (!quiz) {
    return <div>Loading...</div>;
  }

  if (!quiz.Questions) {
    return <div>No questions available for this quiz.</div>;
  }

  const currentQuestion = currentQuestions[currentQuestionIndex];

  // Adjust total questions displayed based on retakeMode
  const totalQuestionsToDisplay = retakeMode ? currentQuestions.length : cumulativeTotalQuestionsRef.current;

  // Adjust current question number
  const currentQuestionNumber = retakeMode
    ? currentQuestionIndex + 1
    : cumulativeQuestionNumberRef.current + 1;

  return (
    <div className="flex flex-col min-h-screen bg-custom-brownbg border border-blue">
      <div className="p-4 md:py-6 flex flex-1 flex-col gap-5">
        <div className="flex-1 bg-white p-14 md:p-5 gap-4 w-full shadow-custom-darkblue rounded-md flex flex-col">
          <div
            className={`flex border justify-between border-blue shadow-custom-darkblue p-2 rounded-md ${getDifficultyClass(
              currentQuestion.Difficulty_Level
            )}`}
          >
            <div className="text-xl font-bold text-left text-custom-gray-900">{quiz.Quiz_Name}</div>
            <div className="text-xl font-bold text-left text-custom-gray-900">{quiz.Question_Bank_Name}</div>
            <div className="text-xl font-bold text-left text-gray-900 mb-2">
              Question {currentQuestionNumber} of {totalQuestionsToDisplay}
            </div>
            <div className="text-xl font-bold text-left text-gray-900">
              Time Left: {timeLeft !== null ? formatTime(timeLeft) : "Loading..."}
            </div>
            <div className="text-xl font-bold text-left text-gray-900">
              Points: {studentPoints} / {totalPointsPossible}
            </div>
          </div>

          {currentQuestion ? (
            <div
              className={`flex flex-1 flex-col shadow-custom-darkblue border border-blue items-center justify-center p-2 rounded-md ${getDifficultyClass(
                currentQuestion.Difficulty_Level
              )}`}
            >
              <div className="text-2xl font-bold text-gray-900">
                {currentQuestion.Question_Text}
              </div>
            </div>
          ) : (
            <div>No questions available for this quiz.</div>
          )}
        </div>

        {currentQuestion && currentQuestion.Question_Type === "Multiple Choice" && (
          <div className="bg-white grid grid-col-4 p-14 text-left md:p-5 w-full shadow-custom-darkblue rounded-md">
            <div className="grid grid-row-2 gap-4">
              {currentQuestion.Choices.map((choice, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600"
                    checked={
                      Array.isArray(answers[currentQuestion.id]) &&
                      answers[currentQuestion.id].includes(choice.Choice_Text)
                    }
                    onChange={(e) => {
                      const prevAnswers = Array.isArray(answers[currentQuestion.id])
                        ? answers[currentQuestion.id]
                        : [];
                      const newAnswers = e.target.checked
                        ? [...prevAnswers, choice.Choice_Text]
                        : prevAnswers.filter((ans) => ans !== choice.Choice_Text);
                      handleAnswerChange(currentQuestion.id, newAnswers);
                    }}
                  />
                  <span className="p-2 border text-xl border-gray-300 rounded-md w-full">
                    {choice.Choice_Text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        {currentQuestion &&
          (currentQuestion.Question_Type === "True or False" ||
            currentQuestion.Question_Type === "Yes or No") && (
            <div className="bg-white text-left p-14 md:p-5 w-full shadow-custom-darkblue rounded-md">
              <div className="grid grid-row gap-4">
                {currentQuestion.Choices.map((choice, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <input
                      type="radio"
                      name={currentQuestion.id}
                      className="form-radio h-5 w-5 text-blue-600"
                      checked={answers[currentQuestion.id] === choice.Choice_Text}
                      onChange={() => handleAnswerChange(currentQuestion.id, choice.Choice_Text)}
                      aria-checked={answers[currentQuestion.id] === choice.Choice_Text}
                      aria-label={choice.Choice_Text}
                    />
                    <span className="p-2 border text-xl border-gray-300 rounded-md w-full">
                      {choice.Choice_Text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

        {currentQuestion && currentQuestion.Question_Type === "Identification" && (
          <div className="bg-white p-14 text-left md:p-5 w-full shadow-custom-darkblue rounded-md">
            <div className="grid grid-row gap-4">
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  className="p-2 border text-xl border-gray-300 rounded-md w-full"
                  placeholder="Enter your answer here"
                  value={answers[currentQuestion.id] || ""}
                  onChange={(e) => handleIdentificationChange(e.target.value)}
                  aria-label="Identification answer"
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end">
          <button
            onClick={handleSubmitQuiz}
            className="text-sm bg-custom-blue bg-gradient-to-r from-midp to-pink px-4 py-2 text-custom-brownbg font-semibold rounded-md"
          >
            {currentQuestionIndex < currentQuestions.length - 1 ? "Next Question" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentQuizTakingPage;
