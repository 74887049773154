import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import logoDarkIcon from '../assets/images/logoDark-icon.png';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const SignInPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();
    const db = getFirestore();

    const handleLogin = async () => {
        if (!email || !password || !firstName || !lastName || !username || !role) {
            setError("All fields are required!");
            return;
        }

        const userData = {
            Email: email,
            First_Name: firstName,
            Last_Name: lastName,
            Password: password,
            Role: role,
            Username: username
        };

        try {
            const rolePath = role === 'Student' ? 'tbl_students' : 'tbl_teacher';
            const docRef = doc(db, rolePath, email); // Using email as the document ID
            await setDoc(docRef, userData);
            setSuccess("Your account has been created successfully!");
            // Remove immediate navigation
        } catch (err) {
            setError("Failed to create account. Error: " + err.message);
        }
    };

    return (
        <div className="bg-yellow h-screen flex flex-col gap-5 p-10 items-center justify-center">
            <div className="bg-white border border-blue rounded-md py-5 w-full max-w-xl">
                <div className="flex justify-center gap-10 items-center">
                    <img
                        src={logoDarkIcon}
                        className="w-20 h-20 md:w-24 md:h-24"
                    />
                    <span
                        className="text-3xl md:text-3xl font-bold text-center text-blue cursor-pointer"
                    >
                        KWISAYSAYAN
                    </span>
                </div>
            </div>
            <section className="login border border-blue flex flex-col gap-6 bg-white rounded-lg p-6 sm:p-8 md:p-10 w-full max-w-xl">
                <h2 className="text-2xl sm:text-3xl font-bold text-blue text-center">Create account</h2>
                <section className="flex flex-col gap-4">
                    <label htmlFor="firstName" className="text-lg sm:text-xl text-left font-semibold text-blue">First Name</label>
                    <input
                        placeholder="Enter your first name"
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    />
                    <label htmlFor="lastName" className="text-lg sm:text-xl text-left font-semibold text-blue">Last Name</label>
                    <input
                        placeholder="Enter your last name"
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    />
                    <label htmlFor="email" className="text-lg sm:text-xl text-left font-semibold text-blue">Email</label>
                    <input
                        placeholder="Enter your email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    />
                    <label htmlFor="username" className="text-lg sm:text-xl text-left font-semibold text-blue">Username</label>
                    <input
                        placeholder="Enter your username"
                        type="text"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    />
                    <label htmlFor="role" className="text-lg sm:text-xl text-left font-semibold text-blue">Role</label>
                    <select
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    >
                        <option value="">Select your role</option>
                        <option value="Student">Student</option>
                        <option value="Teacher">Teacher</option>
                    </select>
                    <label htmlFor="password" className="text-lg sm:text-xl text-left font-semibold text-blue">Password</label>
                    <input
                        placeholder="Enter your password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    />

                    <div className="flex mt-2 gap-2">
                        <span
                            className="text-sm sm:text-base font-semibold text-center text-blue cursor-pointer"
                        >
                            Already have an account?
                        </span>

                        <span
                            className="text-sm sm:text-base font-semibold text-center text-red cursor-pointer hover:text-blue hover:border hover:border-red rounded-md hover:px-1"
                            onClick={() => navigate("/")}
                        >
                            LOG IN
                        </span>
                    </div>
                </section>
                <button
                    onClick={handleLogin}
                    className="h-12 sm:h-14 bg-yellow text-blue border-2 border-blue hover:bg-yellow hover:text-red hover:border-red rounded-lg w-full text-lg sm:text-xl font-bold"
                >
                    SIGN IN
                </button>

                {error && (
                    <p className="text-red text-sm sm:text-base mt-4 text-center">{error}</p>
                )}
            </section>

            {success && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold mb-4 text-center text-green-500">Success!</h3>
                        <p className="text-lg text-center">{success}</p>
                        <button
                            onClick={() => navigate('/')}
                            className="mt-6 bg-green-500 text-white px-4 py-2 rounded-md"
                        >
                            Go to Login page
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SignInPage;
