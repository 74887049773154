import viewarrowbutton from "../../assets/images/viewArrowDark-icon.png";

function DashboardPage() {
  const leaderboardData = [
    "Jane Doe",
    "John Smith",
    "Alex Johnson",
    "Maria Garcia",
    "Chris Lee",
    "Ashley Brown",
    "Michael Davis",
    "Sarah Wilson",
    "David Martinez",
    "Emily Clark",
  ];

  return (
    <div className="flex-1 h-screen bg-custom-brownbg pb-4 md:pb-10">
      {/* Header */}
      <header className="flex items-center shadow-custom-darkblue h-16 md:h-20">
        <div className="flex p-2 md:p-4 text-lg md:text-3xl font-bold text-darkp">
          <h1>DASHBOARD</h1>
        </div>
      </header>

      {/* Main Content */}
      <div className="p-4 md:p-10 space-y-6">
      <div className="flex flex-col md:flex-row flex-1 w-full items-center gap-2">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search..."

            className="w-full md:w-1/4 p-2 text-red text-sm md:text-base border border-blue rounded-md"
          />
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h2 className="text-3xl text-left font-bold text-darkp mb-4">
            🏆 Leaderboard
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              {leaderboardData.slice(0, 5).map((name, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-between p-3 rounded-md ${
                    index < 3 ? "bg-gray-50" : ""
                  } shadow-sm`}
                >
                  <div className="flex items-center">
                    <div
                      className={`h-8 w-8 rounded-full flex items-center justify-center text-sm font-bold text-white ${
                        index === 0
                          ? "bg-yellow"
                          : index === 1
                          ? "bg-yellow"
                          : index === 2
                          ? "bg-yellow"
                          : "bg-gray-400"
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className="ml-3 font-medium text-darkp">{name}</span>
                  </div>
                  <span className="text-sm font-semibold text-darkp">{`Score: ${
                    98 - index * 3
                  }`}</span>
                </div>
              ))}
            </div>

            <div className="space-y-2">
              {leaderboardData.slice(5, 10).map((name, index) => (
                <div
                  key={index + 5}
                  className={`flex items-center justify-between p-3 rounded-md ${
                    index < 3 ? "bg-gradient-to-r from-yellow-200 to-yellow-400" : ""
                  } shadow-sm`}
                >
                  <div className="flex items-center">
                    <div
                      className={`h-8 w-8 rounded-full flex items-center justify-center text-sm font-bold text-white ${
                        index + 5 === 5
                          ? "bg-gray-400"
                          : index + 5 === 6
                          ? "bg-gray-400"
                          : index + 5 === 7
                          ? "bg-gray-400"
                          : "bg-gray-400"
                      }`}
                    >
                      {index + 6}
                    </div>
                    <span className="ml-3 font-medium text-darkp">{name}</span>
                  </div>
                  <span className="text-sm font-semibold text-darkp">{`Score: ${
                    98 - (index + 5) * 3
                  }`}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Container with Sub-Containers */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white rounded-lg shadow-md p-4">
            <div className="flex items-center h-full">
              <div className="flex-1">
                <div className="flex flex-col items-center justify-center h-full">
                  <h1 className="text-5xl font-bold text-darkp">20</h1>
                  <span className="text-gray-600 text-sm">Total Quizzes Created</span>
                </div>
              </div>

              {/* Right Container: Display Text "Quiz Created" */}
              <div className="flex-1">
                <div className="flex flex-col items-center justify-center h-full">
                  <h2 className="text-3xl font-bold text-darkp">📚Quiz Created</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Strengths and Weaknesses Box */}
          <div className="bg-white rounded-lg shadow-md p-4">
            <h3 className="text-3xl font-bold text-darkp mb-3">💪 Strengths and Weaknesses</h3>
            {/* Strengths and Weaknesses Content Goes Here */}
          </div>

          {/* Quiz Result per Section Box */}
          <div className="bg-white rounded-lg shadow-md p-4">
            <h3 className="text-3xl font-bold text-darkp mb-3">📊 Quiz Result per Section</h3>

          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
