import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../../assets/images/arrow-icon.png";
import { db } from "../../../firebaseConfig.js";
import { collection, getDocs, query, where } from "firebase/firestore";

function QuestionBankPage() {
  // State Variables
  const [selectedOption, setSelectedOption] = useState("by bank name");
  const [gradeLevel, setGradeLevel] = useState("All");
  const [difficultyLevel, setDifficultyLevel] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const letters = ["A", "B", "C", "D", "E"];
  const [questionBanks, setQuestionBanks] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [choices, setChoices] = useState([]);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();

  // Fetch Data from Firestore
  useEffect(() => {
    const userId = localStorage.getItem("userID");
    if (!userId) {
      console.error("User not logged in.");
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch Question Banks
        const qBanks = query(
          collection(db, "tbl_questionBank"),
          where("User_ID", "==", userId)
        );
        const querySnapshotBanks = await getDocs(qBanks);
        const banks = querySnapshotBanks.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQuestionBanks(banks);

        // Fetch Questions
        const qQuestions = collection(db, "tbl_question");
        const querySnapshotQuestions = await getDocs(qQuestions);
        const fetchedQuestions = querySnapshotQuestions.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQuestions(fetchedQuestions);

        // Fetch Choices
        const qChoices = collection(db, "tbl_choices");
        const querySnapshotChoices = await getDocs(qChoices);
        const fetchedChoices = querySnapshotChoices.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChoices(fetchedChoices);

        // Fetch Answers
        const qAnswers = collection(db, "tbl_answers");
        const querySnapshotAnswers = await getDocs(qAnswers);
        const fetchedAnswers = querySnapshotAnswers.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAnswers(fetchedAnswers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Handlers for Dropdowns and Search Input
  const handleDropdownChange = (event) => setSelectedOption(event.target.value);
  const handleDifficultyChange = (event) => setDifficultyLevel(event.target.value);
  const handleGradeLevelChange = (event) => setGradeLevel(event.target.value);
  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  // Handlers for Navigation Buttons
  const handleCreateQuestionBankClick = () =>
    navigate("/Teacher/Library/QuestionBank/Create");
  const handleViewbankClick = (bankId) =>
    navigate(`/teacher/Library/QuestionBank/View/${bankId}`);

  // Function to Escape Special Characters in Regex
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  // Function to Get Search Terms, Including Converting Textual Months to Numerical
  const getSearchTerms = (query) => {
    const trimmedQuery = query.trim().toLowerCase();
    if (!trimmedQuery) return [];

    const searchTerms = [];

    const monthMap = {
      january: "01",
      february: "02",
      march: "03",
      april: "04",
      may: "05",
      june: "06",
      july: "07",
      august: "08",
      september: "09",
      october: "10",
      november: "11",
      december: "12",
    };

    const monthNames = Object.keys(monthMap);
    const monthRegex = new RegExp(`\\b(${monthNames.join("|")})\\b`, "i");
    const monthMatch = trimmedQuery.match(monthRegex);

    if (monthMatch) {
      const month = monthMatch[1].toLowerCase();
      const monthNumber = monthMap[month];
      if (monthNumber) {
        searchTerms.push(monthNumber);
      }

      // Check for Month and Day (e.g., "November 13")
      const monthDayRegex = new RegExp(
        `\\b(${monthNames.join("|")})\\s+(\\d{1,2})\\b`,
        "i"
      );
      const monthDayMatch = trimmedQuery.match(monthDayRegex);
      if (monthDayMatch) {
        const day = monthDayMatch[2].padStart(2, "0");
        const mmdd = `${monthMap[month]}/${day}`;
        searchTerms.push(mmdd);
      }

      // Check for Month, Day, and Year (e.g., "November 13, 2024")
      const monthDayYearRegex = new RegExp(
        `\\b(${monthNames.join("|")})\\s+(\\d{1,2}),\\s+(\\d{4})\\b`,
        "i"
      );
      const monthDayYearMatch = trimmedQuery.match(monthDayYearRegex);
      if (monthDayYearMatch) {
        const day = monthDayYearMatch[2].padStart(2, "0");
        const year = monthDayYearMatch[3].slice(-2); // Get last two digits
        const mmddyy = `${monthMap[month]}/${day}/${year}`;
        searchTerms.push(mmddyy);
      }
    }

    // Check for Year Only (e.g., "2024")
    const yearRegex = /\b(\d{4})\b/;
    const yearMatch = trimmedQuery.match(yearRegex);
    if (yearMatch) {
      const year = yearMatch[1].slice(-2); // Get last two digits
      searchTerms.push(year);
    }

    // Always include the original query for partial matches
    searchTerms.push(trimmedQuery.replace(/\s+/g, "/")); // Replace spaces with '/' for numerical matching

    return searchTerms;
  };

  // Function to Check if Any Field Matches the Search Terms
  const matchesSearch = (item, fields, searchTerms) => {
    if (searchTerms.length === 0) return true;
    return fields.some((field) => {
      const value = field(item);
      if (value) {
        const lowerValue = value.toLowerCase();
        return searchTerms.some((term) => lowerValue.includes(term));
      }
      return false;
    });
  };

  // Function to Highlight Search Terms in Text
  const highlightText = (text, searchTerms) => {
    if (!searchTerms || searchTerms.length === 0) return text;
    // Escape all search terms
    const escapedTerms = searchTerms.map((term) => escapeRegExp(term));
    // Create a regex that matches any of the search terms
    const regex = new RegExp(`(${escapedTerms.join("|")})`, "gi");
    const parts = String(text).split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          style={{ backgroundColor: "#FFC233", color: "#0C36AF", fontWeight: "bold" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Function to Format Date Consistently as "MM/DD/YY, hh:mm AM/PM"
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  // Compute Search Terms Using useMemo for Performance Optimization
  const searchTerms = useMemo(() => getSearchTerms(searchQuery), [searchQuery]);

  // Precompute a Mapping from Bank ID to Subtopics
  const bankSubtopicsMap = useMemo(() => {
    const map = {};
    questionBanks.forEach((bank) => {
      const relatedQuestions = questions.filter((q) => q.Question_Bank_ID === bank.id);
      const uniqueSubtopics = [
        ...new Set(relatedQuestions.map((q) => q.Subtopic).filter(Boolean)),
      ];
      map[bank.id] = uniqueSubtopics;
    });
    return map;
  }, [questionBanks, questions]);

  // Filter Question Banks Based on Selected Options and Search Query
  const filteredQuestionBanks = useMemo(() => {
    return questionBanks.filter((bank) => {
      // Filter based on selectedOption and gradeLevel
      let matchesFilter = true;
      if (selectedOption === "by bank name") {
        matchesFilter =
          gradeLevel === "All" ||
          (bank.Grade_Level &&
            bank.Grade_Level.toLowerCase() === gradeLevel.toLowerCase());
      }

      // Define fields to search
      const searchFields = [
        (b) => b.Question_Bank_Name || "",
        (b) => b.Grade_Level || "",
        (b) => b.Topic || "",
        (b) => formatDate(b.Creation_Date) || "",
      ];

      const matchesSearchQuery = matchesSearch(bank, searchFields, searchTerms);

      return matchesFilter && matchesSearchQuery;
    });
  }, [questionBanks, selectedOption, gradeLevel, searchTerms]);

  // Filter Questions Based on Selected Options and Search Query
  const filteredQuestions = useMemo(() => {
    return questions
      .filter((question) => {
        const questionBank = questionBanks.find(
          (bank) => bank.id === question.Question_Bank_ID
        );

        const matchesUserBank =
          questionBank && questionBank.User_ID === localStorage.getItem("userID");
        const matchesGradeLevel =
          gradeLevel === "All" ||
          (questionBank &&
            questionBank.Grade_Level?.toLowerCase() === gradeLevel.toLowerCase());
        const matchesDifficultyLevel =
          difficultyLevel === "All" ||
          (question.Difficulty_Level &&
            question.Difficulty_Level.toLowerCase() ===
              difficultyLevel.toLowerCase());

        // Define fields to search
        const searchFields = [
          (q) => q.Question_Text || "",
          (q) => q.Subtopic || "", // Accessing Subtopic directly from question
          (q) => questionBank?.Grade_Level || "",
          (q) => questionBank?.Topic || "",
          (q) => formatDate(questionBank?.Creation_Date) || "",
        ];

        const matchesSearchQuery = matchesSearch(question, searchFields, searchTerms);

        return (
          matchesUserBank &&
          matchesGradeLevel &&
          matchesDifficultyLevel &&
          matchesSearchQuery
        );
      })
      .map((question) => ({
        ...question,
        Choices: choices
          .filter((choice) => choice.Question_ID === question.id)
          .map((choice) => ({
            text: choice.Choice_Text,
            isCorrect: choice.Is_Correct,
          })),
        Answer:
          answers.find((answer) => answer.Question_ID === question.id)
            ?.Answer_text || "",
      }));
  }, [
    questions,
    questionBanks,
    gradeLevel,
    difficultyLevel,
    searchTerms,
    choices,
    answers,
  ]);

  return (
    <div className="flex-1 min-h-screen bg-custom-brownbg pb-4 md:pb-10">
      {/* Inline Highlight Styling */}
      <style>{`
        .highlight {
          background-color: yellow;
        }
      `}</style>

      {/* Header */}
      <header className="flex items-center shadow-custom-darkblue h-16 md:h-20">
        <div className="flex p-2 md:p-4 text-lg md:text-2xl items-center font-bold ">
          <h1 className="text-blue">LIBRARY</h1>
          <img
            src={arrowIcon}
            alt="Arrow Page"
            className="w-3 h-5 mx-5 md:w-4 md:h-7"
          />
          <h1 className="text-red">QUESTION BANK</h1>
        </div>
      </header>

      {/* Search and Filters */}
      <div className="flex flex-col md:flex-row md:items-center md:gap-2 px-6 pt-5 md:pt-6">
        <div className="flex flex-col md:flex-row flex-1 w-full items-center gap-2">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full md:w-1/4 p-2 text-red text-sm md:text-base border border-blue rounded-md"
          />

          {/* Dropdowns */}
          <div className="flex flex-row md:flex-row w-full md:w-1/2 gap-2 mt-2 md:mt-0">
            {/* Search Option Dropdown */}
            <select
              value={selectedOption}
              onChange={handleDropdownChange}
              className="w-full md:w-1/2 p-1 md:p-2 text-xs text-red md:text-base border border-blue rounded-md"
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="All">All</option>
              <option value="by bank name">By question bank name</option>
            </select>

            {/* Difficulty Level Dropdown (Visible when "All" is selected) */}
            {selectedOption === "All" && (
              <select
                value={difficultyLevel}
                onChange={handleDifficultyChange}
                className="w-auto md:w-1/2 p-1 md:p-2 text-xs md:text-base border text-red border-blue rounded-md"
              >
                <option value="All">All</option>
                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Hard">Hard</option>
              </select>
            )}

            {/* Grade Level Dropdown */}
            <select
              value={gradeLevel}
              onChange={handleGradeLevelChange}
              className="w-full md:w-1/2 p-1 md:p-2 text-xs text-red md:text-base border border-blue rounded-md"
            >
              <option value="All">All</option>
              <option value="Grade 4">Grade 4</option>
              <option value="Grade 5">Grade 5</option>
              <option value="Grade 6">Grade 6</option>
              <option value="Grade 7">Grade 7</option>
              <option value="Grade 8">Grade 8</option>
              <option value="Grade 9">Grade 9</option>
              <option value="Grade 10">Grade 10</option>
            </select>
          </div>

          {/* Create Question Bank Button */}
          <button
            className="bg-white border border-red text-red px-4 py-2 text-sm md:text-base font-bold rounded-md shadow-md md:ml-auto mt-2 md:mt-0 self-end hover:bg-red hover:text-white"
            onClick={handleCreateQuestionBankClick}
          >
            Create question bank
          </button>
        </div>
      </div>

      {/* Display Question Banks */}
      {selectedOption === "by bank name" &&
        filteredQuestionBanks.map((bank) => {
          // Count Questions by Difficulty Level
          const easyCount = questions.filter(
            (q) =>
              q.Question_Bank_ID === bank.id &&
              q.Difficulty_Level.toLowerCase() === "easy"
          ).length;
          const mediumCount = questions.filter(
            (q) =>
              q.Question_Bank_ID === bank.id &&
              q.Difficulty_Level.toLowerCase() === "medium"
          ).length;
          const hardCount = questions.filter(
            (q) =>
              q.Question_Bank_ID === bank.id &&
              q.Difficulty_Level.toLowerCase() === "hard"
          ).length;

          // Get Subtopics from the Precomputed Mapping
          const subtopics = bankSubtopicsMap[bank.id] || [];

          return (
            <div
              key={bank.id}
              className="bg-blue p-6 md:p-6 rounded-md shadow-custom-darkblue mx-4 md:mx-6 mt-4 flex flex-col gap-4"
            >
              <div className="flex flex-row items-center gap-4">
                <div className="flex-1 flex flex-col md:flex-row md:items-center md:justify-between md:w-full">
                  <div className="flex-1 flex flex-col items-start">
                    {/* Topic */}
                    <div className="flex gap-2 pb-5 text-white">
                      <span className="text-xl">Topic:</span>
                      <span className="text-white font-semibold text-xs text-left md:text-xl">
                        {highlightText(bank.Topic, searchTerms)}
                      </span>
                    </div>

                    {/* Question Bank Name */}
                    <div className="flex gap-2 pb-2">
                      <span className="text-xl text-white ">Question bank name:</span>
                      <h2 className="text-white text-base md:text-xl font-semibold">
                        {highlightText(bank.Question_Bank_Name, searchTerms)}
                      </h2>
                    </div>

                    {/* Grade Level */}
                    <div className="flex gap-2 pb-2 text-white">
                      <span className="text-xl">Grade level:</span>
                      <span className="text-white font-semibold text-xs md:text-xl">
                        {highlightText(bank.Grade_Level, searchTerms)}
                      </span>
                    </div>

                    {/* Total number of questions */}
                    <div className="flex gap-2 pb-2">
                      <span className="text-xl text-white">Total number of questions:</span>
                      <span className="text-white text-sm md:text-xl font-semibold">
                        {easyCount + mediumCount + hardCount}
                      </span>
                    </div>

                    {/* Date & Time Created */}
                    <div className="flex gap-2 pb-5 text-white">
                      <span className="text-xl">Date & time created:</span>
                      <span className="text-white font-semibold text-xs md:text-xl">
                        {highlightText(formatDate(bank.Creation_Date), searchTerms)}
                      </span>
                    </div>

                    {/* Question Difficulty */}
                    <div className="flex gap-2 text-white">
                      <span className="text-xl">Question Difficulty:</span>
                      <span className="text-white font-semibold text-xs md:text-xl">
                        Easy: {easyCount} | Medium: {mediumCount} | Hard: {hardCount}
                      </span>
                    </div>
                  </div>

                  {/* View Button (Desktop) */}
                  <div className="hidden md:flex md:justify-center md:items-center md:ml-auto">
                    <button
                      className="bg-white text-red px-4 py-2 text-sm md:text-base font-bold rounded-md shadow-md hover:bg-red hover:text-white hover:border border-white"
                      onClick={() => handleViewbankClick(bank.id)}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>

              {/* View Button (Mobile) */}
              <div className="flex md:hidden mt-4">
                <button
                  className="bg-gradient-to-r from-midp to-pink text-custom-textcolor w-full px-4 py-2 text-sm font-bold rounded-md shadow-md hover:bg-yellow-600"
                  onClick={() => handleViewbankClick(bank.id)}
                >
                  View
                </button>
              </div>
            </div>
          );
        })}

      {/* Display Questions */}
      {selectedOption === "All" &&
        filteredQuestions.map((question) => {
          const questionBank = questionBanks.find(
            (bank) => bank.id === question.Question_Bank_ID
          );

          return (
            <div
              key={question.id}
              className="bg-white border-2 border-blue p-6 md:p-6 rounded-md shadow-custom-darkblue mx-4 md:mx-6 mt-4 flex flex-col text-left"
            >
              {/* Topic */}
              <div className="flex gap-2">
                <span className="text-xl text-gray-900">Topic:</span>
                <span className="text-gray-900 font-semibold text-sm md:text-xl">
                  {highlightText(questionBank?.Topic || "Unknown Bank", searchTerms)}
                </span>
              </div>

              {/* Sub-topic */}
              <div className="flex gap-2">
                <span className="text-xl text-gray-900">Sub-topic:</span>
                <span className="text-gray-900 font-semibold text-sm md:text-xl">
                  {highlightText(question.Subtopic || "Unknown Subtopic", searchTerms)}
                </span>
              </div>

              {/* Question Bank Name */}
              <div className="flex mt-2 gap-2">
                <span className="text-xl text-gray-900">Question bank name:</span>
                <span className="text-gray-900 font-semibold text-sm md:text-xl">
                  {highlightText(
                    questionBank?.Question_Bank_Name || "Unknown Bank",
                    searchTerms
                  )}
                </span>
              </div>

              {/* Grade Level */}
              <div className="flex gap-2">
                <span className="text-xl text-gray-900">Grade level:</span>
                <span className="text-gray-900 font-semibold text-sm md:text-xl">
                  {highlightText(
                    questionBank?.Grade_Level || "Unknown Bank",
                    searchTerms
                  )}
                </span>
              </div>

              {/* Date & Time Created */}
              <div className="flex gap-2 pb-4">
                <span className="text-xl text-gray-900">Date & time created:</span>
                <span className="text-gray-900 font-semibold text-sm md:text-xl">
                  {highlightText(
                    formatDate(questionBank?.Creation_Date) || "",
                    searchTerms
                  )}
                </span>
              </div>

              {/* Level of Difficulty */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900">Level of difficulty</h3>
                <p className="text-xl mt-2 text-gray-900">
                  {highlightText(question.Difficulty_Level, searchTerms)}
                </p>
              </div>

              {/* Question Text */}
              <div>
                <h3 className="text-xl mt-2 font-semibold text-gray-900">Question</h3>
                <p className="text-gray-900 text-xl mt-2">
                  {highlightText(question.Question_Text, searchTerms)}
                </p>
              </div>

              {/* Answer or Choices */}
              <div>
                <h3 className="text-xl mt-2 font-semibold text-gray-900">
                  {question.Question_Type === "Identification" ? "Answer" : "Choices"}
                </h3>
                <div className="text-xl grid grid-cols-2 md:grid-cols-2 md:gap-2 gap-4 mt-2">
                  {question.Question_Type === "Identification" ? (
                    <div className="border-2 border-green-600 text-xl font-semibold bg-green-300 p-2 rounded-md">
                      {highlightText(question.Answer, searchTerms)}
                    </div>
                  ) : (
                    question.Choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`p-2 rounded-md text-xl font-semibold ${
                          choice.isCorrect
                            ? "border-2 text-gray-900 border-green-600 bg-green-300"
                            : "text-gray-900 border-2 border-red"
                        }`}
                      >
                        {question.Question_Type === "Multiple Choice" && (
                          <span className="font-semibold">{letters[index]}.</span>
                        )}{" "}
                        {highlightText(choice.text, searchTerms)}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default QuestionBankPage;
