// src/components/QuestionBankViewPage.jsx

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import arrowIcon from "../../../assets/images/arrow-icon.png";
import addDarkIcon from "../../../assets/images/addDark-icon.png";

function QuestionBankViewPage() {
  const [questions, setQuestions] = useState([]);
  const [questionBank, setQuestionBank] = useState(null);
  const [selectedOption, setSelectedOption] = useState("all");
  const [selectedDifficulty, setSelectedDifficulty] = useState("all");
  const navigate = useNavigate();
  const { bankId } = useParams();

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // States for new question
  const [newQuestionType, setNewQuestionType] = useState("");
  const [newQuestionText, setNewQuestionText] = useState("");
  const [newDifficultyLevel, setNewDifficultyLevel] = useState("");
  const [newSubTopic, setNewSubTopic] = useState("");
  const [newChoices, setNewChoices] = useState([
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
  ]);
  const [newTrueFalseAnswer, setNewTrueFalseAnswer] = useState(null);
  const [newYesNoAnswer, setNewYesNoAnswer] = useState(null);
  const [newIdentificationAnswer, setNewIdentificationAnswer] = useState("");

  // States for subtopics
  const [subTopics, setSubTopics] = useState([]);
  const [loadingSubTopics, setLoadingSubTopics] = useState(false);
  const [subTopicsError, setSubTopicsError] = useState("");

  // Fetch question bank and associated questions
  useEffect(() => {
    const fetchQuestionBankData = async () => {
      try {
        // Fetch the specific question bank based on bankId
        const questionBankQuery = query(
          collection(db, "tbl_questionBank"),
          where("__name__", "==", bankId)
        );
        const questionBankSnapshot = await getDocs(questionBankQuery);

        if (!questionBankSnapshot.empty) {
          const questionBankData = questionBankSnapshot.docs[0].data();
          setQuestionBank(questionBankData);

          // Fetch questions associated with the question bank
          const questionQuery = query(
            collection(db, "tbl_question"),
            where("Question_Bank_ID", "==", bankId)
          );
          const questionSnapshot = await getDocs(questionQuery);
          const questionData = await Promise.all(
            questionSnapshot.docs.map(async (doc) => {
              const question = { id: doc.id, ...doc.data() };
              if (question.Question_Type === "Multiple Choice") {
                const choicesQuery = query(
                  collection(db, "tbl_choices"),
                  where("Question_ID", "==", doc.id)
                );
                const choicesSnapshot = await getDocs(choicesQuery);
                question.choices = choicesSnapshot.docs.map((choiceDoc) =>
                  choiceDoc.data()
                );
              } else if (
                question.Question_Type === "True or False" ||
                question.Question_Type === "Yes or No"
              ) {
                const choicesQuery = query(
                  collection(db, "tbl_choices"),
                  where("Question_ID", "==", doc.id)
                );
                const choicesSnapshot = await getDocs(choicesQuery);
                question.answerOptions = choicesSnapshot.docs
                  .map((choiceDoc) => choiceDoc.data())
                  .sort((a, b) =>
                    a.Choice_Text === "True" || a.Choice_Text === "Yes"
                      ? -1
                      : 1
                  );
              } else if (question.Question_Type === "Identification") {
                const answersQuery = query(
                  collection(db, "tbl_answers"),
                  where("Question_ID", "==", doc.id)
                );
                const answersSnapshot = await getDocs(answersQuery);
                question.answers = answersSnapshot.docs.map((answerDoc) =>
                  answerDoc.data()
                );
              }
              return question;
            })
          );
          setQuestions(questionData);
        } else {
          console.error("No question bank found with the provided bankId.");
        }
      } catch (error) {
        console.error("Error fetching question bank data: ", error);
      }
    };

    fetchQuestionBankData();
  }, [bankId]);

  // Fetch subtopics based on the question bank's main topic
  useEffect(() => {
    const fetchSubTopics = async () => {
      if (!questionBank?.Topic) {
        setSubTopics([]);
        return;
      }

      setLoadingSubTopics(true);
      setSubTopicsError("");
      try {
        const q = query(
          collection(db, "tbl_subTopic"),
          where("Main_Topic", "==", questionBank.Topic) // Correct field name
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          if (docData.Sub && Array.isArray(docData.Sub)) {
            setSubTopics(docData.Sub);
          } else {
            setSubTopicsError("No subtopics found for the selected topic.");
          }
        } else {
          setSubTopicsError("No subtopics found for the selected topic.");
        }
      } catch (error) {
        console.error("Error fetching subtopics: ", error);
        setSubTopicsError("Error fetching subtopics.");
      } finally {
        setLoadingSubTopics(false);
      }
    };

    fetchSubTopics();
  }, [questionBank?.Topic]); // Dependency corrected to questionBank.Topic

  // Handlers for filtering questions
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDifficultyChange = (event) => {
    setSelectedDifficulty(event.target.value);
  };

  const handleEditClick = (questionId) => {
    navigate(`/teacher/Library/QuestionBank/Edit/${questionId}`);
  };

  // Handlers for modal
  const openModal = () => {
    setIsModalOpen(true);
    // Reset fields
    setNewSubTopic("");
    setNewQuestionType("");
    setNewQuestionText("");
    setNewDifficultyLevel("");
    setNewChoices([
      { text: "", isCorrect: false },
      { text: "", isCorrect: false },
      { text: "", isCorrect: false },
      { text: "", isCorrect: false },
    ]);
    setNewTrueFalseAnswer(null);
    setNewYesNoAnswer(null);
    setNewIdentificationAnswer("");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handlers for new question inputs
  const handleNewQuestionTypeChange = (event) => {
    setNewQuestionType(event.target.value);
    // Reset dependent fields
    setNewChoices([
      { text: "", isCorrect: false },
      { text: "", isCorrect: false },
      { text: "", isCorrect: false },
      { text: "", isCorrect: false },
    ]);
    setNewTrueFalseAnswer(null);
    setNewYesNoAnswer(null);
    setNewIdentificationAnswer("");
  };

  const handleAddChoice = () => {
    setNewChoices([...newChoices, { text: "", isCorrect: false }]);
  };

  const handleChoiceChange = (index, field, value) => {
    const updatedChoices = [...newChoices];
    updatedChoices[index][field] = value;
    setNewChoices(updatedChoices);
  };

  const handleSubmitNewQuestion = async (event) => {
    event.preventDefault();

    // Basic validation
    if (
      !newQuestionType ||
      !newQuestionText ||
      !newDifficultyLevel ||
      !newSubTopic
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    if (newQuestionType === "Multiple Choice") {
      const validChoices = newChoices.filter(
        (choice) => choice.text.trim() !== ""
      );
      if (validChoices.length < 2) {
        alert("Please provide at least two choices.");
        return;
      }
      if (!validChoices.some((choice) => choice.isCorrect)) {
        alert("Please mark at least one choice as correct.");
        return;
      }
    }

    if (
      (newQuestionType === "True or False" ||
        newQuestionType === "Yes or No") &&
      !(newTrueFalseAnswer || newYesNoAnswer)
    ) {
      alert("Please select an answer.");
      return;
    }

    if (
      newQuestionType === "Identification" &&
      !newIdentificationAnswer.trim()
    ) {
      alert("Please provide an answer for the identification question.");
      return;
    }

    try {
      // Add question to Firestore
      const questionDocRef = await addDoc(collection(db, "tbl_question"), {
        Question_Bank_ID: bankId,
        Question_Type: newQuestionType,
        Question_Text: newQuestionText,
        Difficulty_Level: newDifficultyLevel,
        Topic: questionBank.Topic,
        Subtopic: newSubTopic,
      });

      const questionId = questionDocRef.id;

      // Handle different question types
      if (newQuestionType === "Multiple Choice") {
        for (const choice of newChoices) {
          if (choice.text.trim() === "") continue;
          await addDoc(collection(db, "tbl_choices"), {
            Question_ID: questionId,
            Choice_Text: choice.text.trim(),
            Is_Correct: choice.isCorrect,
          });
        }
      } else if (
        newQuestionType === "True or False" ||
        newQuestionType === "Yes or No"
      ) {
        const choices =
          newQuestionType === "True or False" ? ["True", "False"] : ["Yes", "No"];
        for (const choiceText of choices) {
          await addDoc(collection(db, "tbl_choices"), {
            Question_ID: questionId,
            Choice_Text: choiceText,
            Is_Correct:
              (newQuestionType === "True or False" &&
                choiceText === newTrueFalseAnswer) ||
              (newQuestionType === "Yes or No" &&
                choiceText === newYesNoAnswer),
          });
        }
      } else if (newQuestionType === "Identification") {
        await addDoc(collection(db, "tbl_answers"), {
          Question_ID: questionId,
          Answer_text: newIdentificationAnswer.trim(),
        });
      }

      // Refresh questions list
      const questionQuery = query(
        collection(db, "tbl_question"),
        where("Question_Bank_ID", "==", bankId)
      );
      const questionSnapshot = await getDocs(questionQuery);
      const questionData = await Promise.all(
        questionSnapshot.docs.map(async (doc) => {
          const question = { id: doc.id, ...doc.data() };
          if (question.Question_Type === "Multiple Choice") {
            const choicesQuery = query(
              collection(db, "tbl_choices"),
              where("Question_ID", "==", doc.id)
            );
            const choicesSnapshot = await getDocs(choicesQuery);
            question.choices = choicesSnapshot.docs.map((choiceDoc) =>
              choiceDoc.data()
            );
          } else if (
            question.Question_Type === "True or False" ||
            question.Question_Type === "Yes or No"
          ) {
            const choicesQuery = query(
              collection(db, "tbl_choices"),
              where("Question_ID", "==", doc.id)
            );
            const choicesSnapshot = await getDocs(choicesQuery);
            question.answerOptions = choicesSnapshot.docs
              .map((choiceDoc) => choiceDoc.data())
              .sort((a, b) =>
                a.Choice_Text === "True" || a.Choice_Text === "Yes" ? -1 : 1
              );
          } else if (question.Question_Type === "Identification") {
            const answersQuery = query(
              collection(db, "tbl_answers"),
              where("Question_ID", "==", doc.id)
            );
            const answersSnapshot = await getDocs(answersQuery);
            question.answers = answersSnapshot.docs.map((answerDoc) =>
              answerDoc.data()
            );
          }
          return question;
        })
      );
      setQuestions(questionData);

      // Close modal and reset fields
      closeModal();
    } catch (error) {
      console.error("Error adding question: ", error);
      alert("An error occurred while adding the question.");
    }
  };

  return (
    <div className="flex-1 min-h-screen bg-custom-brownbg pb-4 md:pb-10">
      {/* Header */}
      <header className="flex items-center shadow-custom-darkblue h-16 md:h-20">
        <div className="flex p-2 md:p-4 text-lg md:text-2xl items-center font-bold ">
          <h1 className="text-sm md:text-2xl text-blue">QUESTION BANK</h1>
          <img
            src={arrowIcon}
            alt="Arrow Page"
            className="w-3 h-5 mx-5 md:w-4 md:h-7"
          />
          <h1 className="text-sm md:text-2xl text-red">
            {questionBank?.Question_Bank_Name} - {questionBank?.Grade_Level}
          </h1>
        </div>
      </header>

      {/* Filters and Add Question Button */}
      <div className="mx-2 mt-5 md:p-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <div className="flex flex-row md:flex-row md:items-center md:gap-4 gap-4">
          <div className="flex text-lg text-left md:text-xl font-semibold text-blue">
            Filtered by:
          </div>
          <select
            className="md:p-2 border-2 border-blue text-red rounded md:px-5 md:w-auto"
            onChange={handleDropdownChange}
            value={selectedOption}
          >
            <option value="all">All</option>
            <option value="Multiple Choice">Multiple Choice</option>
            <option value="True or False">True or False</option>
            <option value="Yes or No">Yes or No</option>
            <option value="Identification">Identification</option>
          </select>
          <select
            className="md:p-2 border-2 border-blue text-red rounded md:px-5 md:w-auto"
            onChange={handleDifficultyChange}
            value={selectedDifficulty}
          >
            <option value="all">All</option>
            <option value="Easy">Easy</option>
            <option value="Medium">Medium</option>
            <option value="Hard">Hard</option>
          </select>
        </div>
        <button
          className="text-lg bg-white text-green-600 border border-green-600 hover:bg-green-600 hover:text-white px-5 py-2 rounded-md font-semibold"
          onClick={openModal}
        >
          Add Question
        </button>
      </div>

      {/* Question Bank Details */}
      <div className="flex bg-blue m-6 px-6 py-4 rounded-md justify-between items-center flex-row gap-3">
        <div className="flex gap-2">
          <span className="text-2xl font-semibold text-white">Topic:</span>
          <span className="text-white font-bold text-sm md:text-2xl">
            {questionBank?.Topic || "Unknown Bank"}
          </span>
        </div>

        <div className="flex gap-2">
          <span className="text-2xl font-semibold text-white">
            Total number of questions:
          </span>
          <span className="text-white font-bold text-sm md:text-2xl">
            {questions.length}
          </span>
        </div>

        <div className="flex gap-2">
          <span className="text-2xl font-semibold text-white">Created on:</span>
          <span className="text-white font-bold text-sm md:text-2xl">
            {questionBank?.Creation_Date
              ? new Date(
                  questionBank.Creation_Date.seconds * 1000
                ).toLocaleString()
              : "Unknown Date"}
          </span>
        </div>
      </div>

      {/* Questions List */}
      <div className="mx-4 md:mx-6">
        {questions
          .filter(
            (question) =>
              (selectedOption === "all" || selectedOption === ""
                ? true
                : question.Question_Type === selectedOption) &&
              (selectedDifficulty === "all" ||
              selectedDifficulty === ""
                ? true
                : question.Difficulty_Level === selectedDifficulty)
          )
          .map((question, index) => (
            <div
              key={question.id}
              className="bg-white p-6 md:p-6 rounded-md shadow-custom-red mx-4 md:mx-6 mt-4 flex flex-col gap-2"
            >
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <div className="flex gap-2">
                    <span className="text-xl font-semibold">Sub-topic:</span>
                    <div className="flex text-sm text-left md:text-xl font-bold text-gray-900 md:mb-0">
                      {question.Subtopic}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <span className="text-xl font-semibold">
                      Type of question:
                    </span>
                    <div className="flex text-sm text-left md:text-xl font-bold text-gray-900 md:mb-0">
                      {question.Question_Type}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <span className="text-xl font-semibold">
                      Level of difficulty:
                    </span>
                    <div className="flex text-sm text-left md:text-xl font-bold text-gray-900 md:mb-0">
                      {question.Difficulty_Level}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    className="ml-auto text-sm bg-custom-blue md:text-lg bg-white text-red border-2 border-red hover:bg-red hover:text-white px-6 py-2 font-semibold rounded-md"
                    onClick={() => handleEditClick(question.id)}
                  >
                    Edit
                  </button>
                </div>
              </div>

              <div className="flex gap-2 flex-col text-left">
                <span className="text-xl font-semibold text-gray-900">
                  Question
                </span>
                <span className="text-gray-900 rounded-md font-bold text-base md:text-xl">
                  {index + 1}. {question.Question_Text}
                </span>
              </div>

              {question.Question_Type === "Multiple Choice" && (
                <>
                  <div className="flex text-sm text-left md:text-xl font-semibold text-gray-900 md:mb-0">
                    Choices
                  </div>
                  <div className="grid grid-cols-2 md:grid-row-2 gap-4">
                    {question.choices?.map((choice, idx) => (
                      <div
                        key={idx}
                        className={`p-2 border-2 text-left text-xl font-semibold rounded-md ${
                          choice.Is_Correct
                            ? "border-green-600 bg-green-300"
                            : "border-red-600"
                        }`}
                      >
                        <span className="mr-2">
                          {String.fromCharCode(65 + idx)}.
                        </span>
                        {choice.Choice_Text}
                      </div>
                    ))}
                  </div>
                </>
              )}

              {(question.Question_Type === "True or False" ||
                question.Question_Type === "Yes or No") && (
                <>
                  <div className="flex text-sm text-left md:text-xl font-semibold text-gray-900 md:mb-0">
                    Choices
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {question.answerOptions?.map((option, idx) => (
                      <div
                        key={idx}
                        className={`p-2 border-2 text-left text-xl font-semibold rounded-md ${
                          option.Is_Correct
                            ? "border-green-600 bg-green-300"
                            : "border-red-600"
                        }`}
                      >
                        <span className="mr-2">
                          {String.fromCharCode(65 + idx)}.
                        </span>
                        {option.Choice_Text}
                      </div>
                    ))}
                  </div>
                </>
              )}

              {question.Question_Type === "Identification" && (
                <>
                  <div className="flex text-sm text-left md:text-xl font-semibold text-gray-900 md:mb-0">
                    Answer
                  </div>
                  <div className="p-2 border-2 border-green-600 text-left text-xl font-semibold bg-green-300 rounded-md">
                    {question.answers?.[0]?.Answer_text}
                  </div>
                </>
              )}
            </div>
          ))}
      </div>

      {/* Add Question Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 p-6 overflow-y-auto max-h-screen">
            <h2 className="text-2xl font-bold mb-4">Add New Question</h2>
            <form onSubmit={handleSubmitNewQuestion}>
              {/* Question Type */}
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Question Type:
                </label>
                <div className="flex flex-wrap gap-4">
                  {[
                    "Multiple Choice",
                    "True or False",
                    "Yes or No",
                    "Identification",
                  ].map((type) => (
                    <label key={type} className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="newQuestionType"
                        value={type}
                        checked={newQuestionType === type}
                        onChange={handleNewQuestionTypeChange}
                      />
                      {type}
                    </label>
                  ))}
                </div>
              </div>

              {/* Question Text */}
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Question Text:
                </label>
                <input
                  type="text"
                  className="w-full border border-blue rounded-md p-2 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter question text"
                  value={newQuestionText}
                  onChange={(e) => setNewQuestionText(e.target.value)}
                  required
                />
              </div>

              {/* Difficulty Level */}
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Difficulty Level:
                </label>
                <select
                  className="w-full border border-blue rounded-md p-2 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={newDifficultyLevel}
                  onChange={(e) => setNewDifficultyLevel(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select difficulty level
                  </option>
                  <option value="Easy">Easy</option>
                  <option value="Medium">Medium</option>
                  <option value="Hard">Hard</option>
                </select>
              </div>

              {/* Subtopic */}
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Subtopic:
                </label>
                {loadingSubTopics ? (
                  <p>Loading subtopics...</p>
                ) : subTopicsError ? (
                  <p className="text-red-500">{subTopicsError}</p>
                ) : subTopics.length > 0 ? (
                  <select
                    className="w-full border border-blue rounded-md p-2 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newSubTopic}
                    onChange={(e) => setNewSubTopic(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select a subtopic
                    </option>
                    {subTopics.map((sub, idx) => (
                      <option value={sub} key={idx}>
                        {sub}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="text-red-500">No subtopics available.</p>
                )}
              </div>

              {/* Conditional Rendering Based on Question Type */}
              {newQuestionType === "Multiple Choice" && (
                <div className="mb-4">
                  <label className="block text-lg font-semibold mb-2">
                    Choices:
                  </label>
                  {newChoices.map((choice, idx) => (
                    <div key={idx} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={choice.isCorrect}
                        onChange={(e) =>
                          handleChoiceChange(idx, "isCorrect", e.target.checked)
                        }
                      />
                      <input
                        type="text"
                        className="w-full border border-blue rounded-md p-2 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder={`Choice ${idx + 1}`}
                        value={choice.text}
                        onChange={(e) =>
                          handleChoiceChange(idx, "text", e.target.value)
                        }
                        required
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    className="flex gap-2 items-center bg-white text-yellow-500 border border-yellow-500 hover:bg-yellow-500 hover:text-white px-4 py-2 rounded-md mt-2"
                    onClick={handleAddChoice}
                  >
                    <img
                      src={addDarkIcon}
                      alt="Add Choice"
                      className="w-4 h-4"
                    />
                    Add Choice
                  </button>
                </div>
              )}

              {newQuestionType === "True or False" && (
                <div className="mb-4">
                  <label className="block text-lg font-semibold mb-2">
                    Select Answer:
                  </label>
                  <div className="flex items-center gap-4">
                    {["True", "False"].map((option) => (
                      <label key={option} className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="trueFalseAnswer"
                          value={option}
                          checked={newTrueFalseAnswer === option}
                          onChange={() => setNewTrueFalseAnswer(option)}
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {newQuestionType === "Yes or No" && (
                <div className="mb-4">
                  <label className="block text-lg font-semibold mb-2">
                    Select Answer:
                  </label>
                  <div className="flex items-center gap-4">
                    {["Yes", "No"].map((option) => (
                      <label key={option} className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="yesNoAnswer"
                          value={option}
                          checked={newYesNoAnswer === option}
                          onChange={() => setNewYesNoAnswer(option)}
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {newQuestionType === "Identification" && (
                <div className="mb-4">
                  <label className="block text-lg font-semibold mb-2">
                    Answer:
                  </label>
                  <input
                    type="text"
                    className="w-full border border-blue rounded-md p-2 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter the correct answer"
                    value={newIdentificationAnswer}
                    onChange={(e) => setNewIdentificationAnswer(e.target.value)}
                    required
                  />
                </div>
              )}

              {/* Modal Actions */}
              <div className="flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  className="bg-red-500 text-red px-4 py-2 rounded-md hover:bg-red-600"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                >
                  Add Question
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestionBankViewPage;
