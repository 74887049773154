import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CreateQuizModal from "../../components/Teacher Side/library/createQuizModal";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

function LibraryPage() {
  const navigate = useNavigate(); 
  const db = getFirestore();
  const [selected, setSelected] = useState("Published");
  const [createquizmodal, createquizsetModal] = useState(false);
  const createquiztoggleModal = () => {
    createquizsetModal(!createquizmodal);
  };
  const [quizzes, setQuizzes] = useState([]);
  const [sortOrder, setSortOrder] = useState("mostRecent");
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchQuizzes = async () => {
      const userId = localStorage.getItem("userID"); // Get the User_ID from local storage
      if (!userId) {
        console.error("User ID not found. Please log in.");
        return;
      }

      try {
        // Query Firestore for quizzes created by the current user
        const quizzesQuery = query(
          collection(db, "tbl_quizzes"),
          where("User_ID", "==", userId)
        );
        const querySnapshot = await getDocs(quizzesQuery);
        const quizzesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Filter by status
        let filteredQuizzes = quizzesData.filter(quiz => 
          quiz.Status.toLowerCase() === selected.toLowerCase()
        );

        // Filter by grade level
        if (selectedGradeLevel && selectedGradeLevel !== "All") {
          filteredQuizzes = filteredQuizzes.filter(quiz => 
            quiz.Grade_Level === selectedGradeLevel
          );
        }

        // Filter by search query
        if (searchQuery) {
          filteredQuizzes = filteredQuizzes.filter(quiz => quizMatchesSearchQuery(quiz, searchQuery));
        }

        // Sort based on selected order
        if (sortOrder === "mostRecent") {
          filteredQuizzes.sort((a, b) => b.Creation_Date.toDate() - a.Creation_Date.toDate());
        } else if (sortOrder === "leastRecent") {
          filteredQuizzes.sort((a, b) => a.Creation_Date.toDate() - b.Creation_Date.toDate());
        } else if (sortOrder === "alphabetical") {
          filteredQuizzes.sort((a, b) => a.Quiz_Name.localeCompare(b.Quiz_Name));
        }

        setQuizzes(filteredQuizzes);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };

    fetchQuizzes();
  }, [selected, sortOrder, selectedGradeLevel, searchQuery, db]);

  const handleViewClick = (quiz) => {
    if (selected === "Published") {
      navigate('/Teacher/Library/Publish/View', { state: { quiz } });
    } else if (selected === "Draft") {
      navigate('/Teacher/Library/Draft/View', { state: { quiz } });
    }
  };

  // Function to format the date and time
  const formatDateTime = (date) => {
    return date.toLocaleString('en-US', {
      month: 'long',      // Full month name
      day: 'numeric',     // Numeric day
      year: 'numeric',    // Numeric year
      hour: '2-digit',    // Two-digit hour
      minute: '2-digit',  // Two-digit minute
      hour12: true        // 12-hour format
    });
  };

  // Function to check if quiz matches search query
  const quizMatchesSearchQuery = (quiz, searchQuery) => {
    if (!searchQuery) {
      return true;
    }

    const query = searchQuery.toLowerCase();

    const quizName = quiz.Quiz_Name?.toLowerCase() || '';
    const gradeLevel = quiz.Grade_Level?.toLowerCase() || '';
    const numQuestions = quiz.Number_Of_Questions?.toString().toLowerCase() || '';
    const section = quiz.Section?.toLowerCase() || '';
    const topic = quiz.Topic?.toLowerCase() || '';

    // Format date in multiple ways to match user input
    const creationDate = quiz.Creation_Date.toDate();
    const dateFormats = [
      creationDate.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).toLowerCase(),
      creationDate.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }).toLowerCase(),
      creationDate.toLocaleDateString('en-US').toLowerCase(),
      creationDate.toISOString().split('T')[0].toLowerCase(), // YYYY-MM-DD
    ];

    // Time
    const timeString = creationDate.toLocaleTimeString('en-US').toLowerCase();

    // Fields to search
    const fieldsToSearch = [
      quizName,
      gradeLevel,
      numQuestions,
      section,
      topic,
      ...dateFormats,
      timeString,
    ];

    return fieldsToSearch.some(field => field.includes(query));
  };

  // Function to escape regex special characters
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  // Function to highlight search query in text
  const highlightText = (text, highlight) => {
    if (!text) return null;
    if (!highlight) {
      return text;
    }

    const escapedHighlight = escapeRegExp(highlight);
    const regex = new RegExp(`(${escapedHighlight})`, 'gi');
    const parts = text.toString().split(regex);

    return parts.map((part, i) =>
      regex.test(part) ? <span key={i} className="bg-white text-red font-bold">{part}</span> : part
    );
  };

  return (
    <div className="flex-1 h-screen bg-custom-brownbg pb-4 md:pb-10">
      <header className="flex items-center shadow-custom-darkblue h-16 md:h-20">
        <div className="flex p-2 md:p-4 text-lg md:text-2xl font-bold text-blue">
          <h1>LIBRARY</h1>
        </div>
      </header>

      <div className="flex flex-col md:flex-row md:gap-4">
        <div className="flex-1 flex-shrink p-2 md:p-4 md:items-center md:flex md:h-20">
          <div className="flex flex-row gap-5">
            <button
              className={`w-full md:flex-1 ${
                selected === "Published" ? "text-gray-900 underline underline-offset-2 decoration-gray-900" : "text-blue"
              } text-base md:text-2xl font-bold`}
              onClick={() => setSelected("Published")}
            >
              Published
            </button>
            <button
              className={`w-full md:flex-1 ${
                selected === "Draft" ? "text-gray-900 underline underline-offset-2 decoration-gray-900" : "text-blue"
              } text-base md:text-2xl font-bold`}
              onClick={() => setSelected("Draft")}
            >
              Draft
            </button>
          </div>
        </div>

        <div className="flex-1 flex-shrink p-4 md:p-6 md:ml-auto">
          <div className="flex flex-col md:flex-row gap-2 md:gap-2 md:justify-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full md:w-1/4 p-2 md:p-2 text-sm text-gray-900 md:text-base border border-blue rounded-md"
            />

            <div className="flex flex-row w-full md:w-1/2 gap-2">
              <select
                className="w-1/2 p-1 md:p-2 text-xs md:text-base border text-gray-900 border-blue rounded-md"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <option value="mostRecent">Most Recent</option>
                <option value="leastRecent">Least Recent</option>
                <option value="alphabetical">Alphabetical</option>
              </select>

              <select
                className="w-1/2 p-1 md:p-2 text-xs md:text-base border text-gray-900 border-blue rounded-md"
                value={selectedGradeLevel}
                onChange={(e) => setSelectedGradeLevel(e.target.value)}
              >
                <option value="" disabled>Select grade level</option>
                <option value="All">All</option>
                <option value="Grade 4">Grade 4</option>
                <option value="Grade 5">Grade 5</option>
                <option value="Grade 6">Grade 6</option>
                <option value="Grade 7">Grade 7</option>
                <option value="Grade 8">Grade 8</option>
                <option value="Grade 9">Grade 9</option>
                <option value="Grade 10">Grade 10</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end p-4 md:p-6">
        <button
          className="bg-white text-red border border-red px-4 py-2 text-lg font-bold rounded-md shadow-md hover:bg-red hover:text-white"
          onClick={createquiztoggleModal}
        >
          Create Quiz
        </button>
      </div>

      {quizzes.map((quiz) => (
        <div key={quiz.id} className="bg-blue p-6 md:p-6 rounded-md shadow-custom-darkblue mx-4 md:mx-6 mt-4 flex flex-col gap-4">
          <div className="flex flex-row items-center gap-4">
            <div className="w-24 h-24 md:w-28 md:h-28 bg-gray-300 rounded-md flex items-center justify-center">
              <span className="text-gray-500">Image Here</span>
            </div>
            <div className="flex-1 flex flex-col md:flex-row md:items-center md:justify-between md:w-full">
              <div className="flex-1 flex flex-col items-start">
                <div className="flex gap-2">
                  <span className="text-xl text-white">Name:</span>
                  <h2 className="text-white text-base md:text-xl font-semibold">
                    {highlightText(quiz.Quiz_Name || '', searchQuery)}
                  </h2>
                </div>
                <div className="flex gap-2">
                  <span className="text-lg text-white">Total number of questions:</span>
                  <span className="text-white font-semibold text-sm md:text-lg">
                    {highlightText(quiz.Number_Of_Questions.toString(), searchQuery)} 
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="text-base text-white">Grade level:</span>
                  <span className="text-white text-xs font-semibold md:text-base">
                    {highlightText(quiz.Grade_Level || '', searchQuery)}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="text-base text-white">Section:</span>
                  <span className="text-white text-xs font-semibold md:text-base">
                    {highlightText(quiz.Section || '', searchQuery)}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="text-base text-white">Date & time created:</span>
                  <span className="text-white text-xs font-semibold md:text-base">
                    {highlightText(formatDateTime(quiz.Creation_Date.toDate()), searchQuery)}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="text-base text-white">From question bank:</span>
                  <span className="text-white text-xs font-semibold md:text-base">
                    {highlightText(quiz.Question_Bank_Name || '', searchQuery)}  
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="text-base text-white">Question bank topic:</span>
                  <span className="text-white text-xs font-semibold md:text-base">
                    {highlightText(quiz.Topic || '', searchQuery)}
                  </span>
                </div>
              </div>
              <div className="hidden md:flex md:justify-center md:items-center md:ml-auto">
                <button 
                  className="bg-white text-red px-4 py-2 text-sm md:text-base font-bold rounded-md shadow-md hover:bg-red hover:text-white hover:border border-white"
                  onClick={() => handleViewClick(quiz)}
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div className="flex md:hidden mt-4">
            <button 
              className="bg-white text-custom-textcolor w-full px-4 py-2 text-sm font-bold rounded-md shadow-md hover:bg-yellow-600"
              onClick={() => handleViewClick(quiz)}
            >
              View
            </button>
          </div>
        </div>
      ))}

      <CreateQuizModal
        createquizmodal={createquizmodal}
        createquizsetModal={createquizsetModal}
        createquiztoggleModal={createquiztoggleModal}
      />
    </div>
  );
}

export default LibraryPage;
