import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import libraryLightIcon from "../assets/images/libraryLight-icon.png";
import libraryDarkIcon from "../assets/images/libraryDark-icon.png";
import reportsLightIcon from "../assets/images/reportLight-icon.png";
import reportsDarkIcon from "../assets/images/reportDark-icon.png";
import settingsLightIcon from "../assets/images/settingLight-icon.png";
import settingsDarkIcon from "../assets/images/settingDark-icon.png";
import profileLightIcon from "../assets/images/profileLight-icon.png";
import profileDarkIcon from "../assets/images/profileDark-icon.png";
import navButtonIcon from "../assets/images/sideBar-icon.png";
import navButtonRedIcon from "../assets/images/sideBarRed-icon.png";
import logoDarkIcon from "../assets/images/logo-icon.png";
import logOutRedIcon from "../assets/images/logOutRed-icon.png";
import logOutIcon from "../assets/images/logOut-icon.png";
import qbankDarkIcon from "../assets/images/qbankDark-icon.png";
import qbankLightIcon from "../assets/images/qbankLight-icon.png";
import navButtonLightIcon from "../assets/images/sideBarLight-icon.png";
import dashDarkIcon from "../assets/images/dashboardDark-icon.png";
import dashLightIcon from "../assets/images/dashboardLight-icon.png";

function Nav({ activeLink, setActiveLink }) {
    const [showSubmenu, setShowSubmenu] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const [isNavButtonHovered, setIsNavButtonHovered] = useState(false);
    const [isLogOutButtonHovered, setIsLogOutButtonHovered] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const links = [
        {
            path: "/teacher/Dashboard",
            text: "Dashboard",
            activeIcon: dashLightIcon,
            inactiveIcon: dashDarkIcon,
            hoverIcon: dashLightIcon,
        },
        {
            path: "/teacher/Library",
            text: "Library",
            activeIcon: libraryLightIcon,
            inactiveIcon: libraryDarkIcon,
            hoverIcon: libraryLightIcon,
            submenu: [
                {
                    text: "Question Bank",
                    path: "/teacher/Library/QuestionBank",
                    activeIcon: qbankLightIcon,
                    inactiveIcon: qbankDarkIcon,
                    hoverIcon: qbankLightIcon,
                },
            ],
        },
        {
            path: "/teacher/Reports",
            text: "Reports",
            activeIcon: reportsLightIcon,
            inactiveIcon: reportsDarkIcon,
            hoverIcon: reportsLightIcon,
        },
        {
            path: "/teacher/Profile",
            text: "Profile",
            activeIcon: profileLightIcon,
            inactiveIcon: profileDarkIcon,
            hoverIcon: profileLightIcon,
        },
    ];

    const handleLinkClick = (index) => {
        setActiveLink(index);
    };

    const handleLibraryClick = () => {
        setShowSubmenu((prevState) => !prevState);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleLogout = () => {
        setShowLogoutModal(true);
    };

    const confirmLogout = () => {
        // Perform logout actions here (e.g., clearing auth tokens)
        setShowLogoutModal(false);
        navigate("/"); // Redirect to login page
    };

    const cancelLogout = () => {
        setShowLogoutModal(false);
    };

    return (
        <nav
            className={`${
                isCollapsed
                    ? "relative w-auto"
                    : "fixed sm:relative md:static sm:w-64 md:w-64"
            } sm:fixed md:static md:left-0 md:top-0 h-full sm:z-50 md:z-0 p-5 shadow-custom-red bg-yellow flex flex-col  transition-all duration-300`}
        >
            <div className="flex-grow">
                <div className="flex items-center space-x-2">
                    <img src={logoDarkIcon} alt="Logo" className="w-8 h-8" />
                    {!isCollapsed && (
                        <h1 className="text-2xl font-bold text-blue">
                            KWISAYSAYAN
                        </h1>
                    )}
                </div>

                <div className="space-y-8 mt-14">
                    {links.map((link, index) => (
                        <div key={index}>
                            <Link
                                to={link.path}
                                className={`flex items-center rounded-full ${
                                    location.pathname.startsWith(link.path)
                                        ? "text-red font-semibold bg-white"
                                        : "font-semibold text-blue"
                                } hover:bg-white hover:text-red text-lg`}
                                onClick={() => {
                                    handleLinkClick(index);
                                    if (link.submenu) handleLibraryClick();
                                }}
                                onMouseEnter={() => setHoveredLink(link.path)}
                                onMouseLeave={() => setHoveredLink(null)}
                            >
                                <img
                                    src={
                                        hoveredLink === link.path
                                            ? link.hoverIcon
                                            : location.pathname.startsWith(link.path)
                                            ? link.activeIcon
                                            : link.inactiveIcon
                                    }
                                    alt={link.text}
                                    className="w-5 h-5 m-2"
                                />
                                {!isCollapsed && <span>{link.text}</span>}
                            </Link>

                            {link.submenu && showSubmenu && (
                                <div className="mt-8 space-y-8">
                                    {link.submenu.map((sublink, subIndex) => (
                                        <Link
                                            key={subIndex}
                                            to={sublink.path}
                                            className={`flex items-center rounded-full ${
                                                location.pathname.startsWith(
                                                    sublink.path
                                                )
                                                    ? "text-red font-semibold bg-white"
                                                    : "font-semibold text-blue"
                                            } hover:bg-white hover:text-red text-lg`}
                                            onClick={() => handleLinkClick(index)}
                                            onMouseEnter={() =>
                                                setHoveredLink(sublink.path)
                                            }
                                            onMouseLeave={() =>
                                                setHoveredLink(null)
                                            }
                                        >
                                            <img
                                                src={
                                                    hoveredLink === sublink.path
                                                        ? sublink.hoverIcon
                                                        : location.pathname.startsWith(
                                                              sublink.path
                                                          )
                                                        ? sublink.activeIcon
                                                        : sublink.inactiveIcon
                                                }
                                                alt={sublink.text}
                                                className="w-5 h-5 m-2"
                                            />
                                            {!isCollapsed && (
                                                <span>{sublink.text}</span>
                                            )}
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div
                className={`mt-auto flex ${
                    isCollapsed ? "flex-col items-center gap-5" : "justify-between gap-2"
                }`}
            >
                <button
                    onClick={handleLogout}
                    onMouseEnter={() => setIsLogOutButtonHovered(true)}
                    onMouseLeave={() => setIsLogOutButtonHovered(false)}
                    className={`rounded-full flex items-center justify-center ${
                        isCollapsed
                            ? `p-2 ${
                                  isLogOutButtonHovered ? "bg-red" : "bg-white"
                              }`
                            : "bg-white flex-1 text-red hover:bg-red hover:text-white"
                    }`}
                >
                    {isCollapsed ? (
                        <img
                            src={isLogOutButtonHovered ? logOutIcon : logOutRedIcon}
                            alt="Log Out"
                            className="w-5 h-5"
                        />
                    ) : (
                        <span className="font-semibold text-lg">Log Out</span>
                    )}
                </button>

                <button
                    onClick={toggleCollapse}
                    onMouseEnter={() => setIsNavButtonHovered(true)}
                    onMouseLeave={() => setIsNavButtonHovered(false)}
                    className={`rounded-full ${
                        isNavButtonHovered ? "bg-blue" : "bg-white"
                    } p-2 flex items-center justify-center`}
                >
                    <img
                        src={isNavButtonHovered ? navButtonLightIcon : navButtonIcon}
                        alt="Collapse/Expand Navigation"
                        className="w-5 h-5"
                    />
                </button>
            </div>

            {showLogoutModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="bg-custom-brownbg  border border-white rounded-lg p-6 z-50">
                        <h2 className="text-xl text-blue font-semibold mb-4">
                            Are you sure you want to log out?
                        </h2>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={cancelLogout}
                                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmLogout}
                                className="px-4 py-2 bg-blue text-yellow hover:bg-yellow hover:text-blue font-bold rounded hover:bg-red-600"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
}

export default Nav;
