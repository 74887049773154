import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import libraryLightIcon from "../assets/images/libraryLight-icon.png";
import libraryDarkIcon from '../assets/images/libraryDark-icon.png';
import reportsBeigeIcon from '../assets/images/reportLight-icon.png';
import reportsDarkIcon from '../assets/images/reportDark-icon.png';
import settingsBeigeIcon from '../assets/images/settingLight-icon.png';
import settingsDarkIcon from '../assets/images/settingDark-icon.png';
import profileBeigeIcon from '../assets/images/profileLight-icon.png';
import profileDarkIcon from '../assets/images/profileDark-icon.png';
import navButtonIcon from '../assets/images/sideBar-icon.png';
import logoDarkIcon from '../assets/images/logo-icon.png'; // Import the logo icon
import qbankDarkIcon from "../assets/images/qbankDark-icon.png";
import qbankLightIcon from "../assets/images/qbankLight-icon.png";
import logOutIcon from "../assets/images/logOut-icon.png";
import logOutRedIcon from "../assets/images/logOutRed-icon.png";
import navButtonLightIcon from "../assets/images/sideBarLight-icon.png";
import dashDarkIcon from "../assets/images/dashboardDark-icon.png";
import dashLightIcon from "../assets/images/dashboardLight-icon.png";
import profileLightIcon from "../assets/images/profileLight-icon.png";
import activityLightIcon from "../assets/images/activityLight-icon.png";
import activityDarkIcon from "../assets/images/activityDark-icon.png";


function NavStudent({ activeLink, setActiveLink }) {
    const [showSubmenu, setShowSubmenu] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false); // State to handle collapsing
    const [hoveredLink, setHoveredLink] = useState(null);
    const [isNavButtonHovered, setIsNavButtonHovered] = useState(false);
    const [isLogOutButtonHovered, setIsLogOutButtonHovered] = useState(false);
    const location = useLocation(); // Get the current location

    const links = [
        {
            path: "/student/Dashboard",
            text: "Dashboard",
            activeIcon: dashLightIcon,
            inactiveIcon: dashDarkIcon,
            hoverIcon:dashLightIcon,
        },
        {
            path: "/student/Profile",
            text: "Profile",
            activeIcon: profileLightIcon,
            inactiveIcon: profileDarkIcon,
            hoverIcon:profileLightIcon,
        },
        {
            path: "/student/Activity",
            text: "Activity",
            activeIcon: activityLightIcon,
            inactiveIcon: activityDarkIcon,
            hoverIcon:activityLightIcon,
        },

    ];

    const handleLinkClick = (index) => {
        setActiveLink(index);
    };

    const handleLibraryClick = () => {
        setShowSubmenu(prevState => !prevState); // Toggle submenu visibility
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed); // Toggle collapse state
    };

    return (
        <nav
            className={`${
                isCollapsed
                    ? "relative w-auto"
                    : "fixed sm:relative md:static sm:w-64 md:w-64"
            } sm:fixed md:static md:left-0 md:top-0 h-full sm:z-50 md:z-0 p-5 bg-yellow shadow-custom-red flex flex-col transition-all duration-300`}
        >
            <div className="flex-grow">
                <div className="flex items-center space-x-2">
                    <img src={logoDarkIcon} alt="Logo" className="w-8 h-8" />
                    {!isCollapsed && (
                        <h1 className="text-2xl font-bold text-blue">
                            KWISAYSAYAN
                        </h1>
                    )}
                </div>

                <div className="space-y-8 mt-14">
                    {links.map((link, index) => (
                        <div key={index}>
                            {/* For Library, toggle the submenu on click */}
                            <Link
                                to={link.path}
                                className={`flex items-center rounded-full ${
                                    location.pathname.startsWith(link.path) // Use startsWith instead of strict equality check
                                        ? "text-red font-semibold bg-white" // Selected link styling
                                        : "font-semibold text-blue"
                                } hover:bg-white hover:text-red text-lg`}
                                onClick={() => {
                                    handleLinkClick(index);
                                    if (link.submenu) handleLibraryClick(); // Toggle submenu for links with submenu
                                }}
                                onMouseEnter={() => setHoveredLink(link.path)}
                                onMouseLeave={() => setHoveredLink(null)}
                            >
                                 <img
                                    src={
                                        hoveredLink === link.path
                                            ? link.hoverIcon
                                            : location.pathname.startsWith(link.path)
                                            ? link.activeIcon
                                            : link.inactiveIcon
                                    }
                                    alt={link.text}
                                    className="w-5 h-5 m-2"
                                />
                                {!isCollapsed && <span>{link.text}</span>}
                            </Link>

                            {/* Show submenu if available */}
                            {link.submenu && (
                                <div className={`space-y-2 ml-${isCollapsed ? '0' : '8'} ${showSubmenu ? 'block' : 'hidden'}`}>
                                    {link.submenu.map((submenu, idx) => (
                                        <Link
                                            key={idx}
                                            to={submenu.path}
                                            className={`flex items-center rounded-full mt-8 ${
                                                location.pathname.startsWith(submenu.path) // Use startsWith here as well
                                                    ? "text-darkp font-semibold bg-custom-brownbg" // Selected link styling with background
                                                    : "text-custom-textcolor"
                                            } hover:bg-white hover:text-darkp`} // Hover effect similar to main nav
                                        >
                                            <img
                                                src={
                                                    location.pathname.startsWith(submenu.path) // Use startsWith here too
                                                        ? submenu.activeIcon
                                                        : submenu.inactiveIcon
                                                }
                                                alt={submenu.text}
                                                className="w-5 h-5 m-2"
                                            />
                                            {/* Display the submenu label only if not collapsed */}
                                            {!isCollapsed && <span>{submenu.text}</span>}
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div
                className={`mt-auto flex ${
                    isCollapsed ? "flex-col items-center gap-5" : "justify-between gap-2"
                }`}
            >
                <button
                    onClick={() => console.log("Log Out clicked")}
                    onMouseEnter={() => setIsLogOutButtonHovered(true)}
                    onMouseLeave={() => setIsLogOutButtonHovered(false)}
                    className={`rounded-full flex items-center justify-center ${
                        isCollapsed
                            ? `p-2 ${
                                isLogOutButtonHovered ? "bg-red" : "bg-white"
                            }`
                            : "bg-white flex-1 text-red hover:bg-red hover:text-white"
                    }`}
                >
                    {isCollapsed ? (
                        <img
                            src={isLogOutButtonHovered ? logOutIcon : logOutRedIcon}
                            alt="Log Out"
                            className="w-5 h-5"
                        />
                    ) : (
                        <span className="font-semibold text-lg">Log Out</span>
                    )}
                </button>

                <button
                    onClick={toggleCollapse}
                    onMouseEnter={() => setIsNavButtonHovered(true)}
                    onMouseLeave={() => setIsNavButtonHovered(false)}
                    className={`rounded-full ${
                        isNavButtonHovered ? "bg-blue" : "bg-white"
                    } p-2 flex items-center justify-center`}
                >
                    <img
                        src={isNavButtonHovered ? navButtonLightIcon : navButtonIcon}
                        alt="Collapse/Expand Navigation"
                        className="w-5 h-5"
                    />
                </button>
            </div>
        </nav>
    );
}

export default NavStudent;