// CreateQuizModal.js
import React, { useState, useEffect } from 'react';
import xButton from '../../../assets/images/xButtonLight-icon.png';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebaseConfig.js';

export default function CreateQuizModal({ createquizmodal, createquiztoggleModal }) {
  const [topicName, setTopicName] = useState('');
  const [section, setSection] = useState('');
  const [questionBanks, setQuestionBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [isChecking, setIsChecking] = useState(false); // Loading state

  // State variables for error messages
  const [errors, setErrors] = useState({
    topicName: '',
    section: '',
    selectedBank: '',
    gradeLevel: '',
    quizNameDuplicate: '',
  });

  const navigate = useNavigate();

  // Fetch question banks on component mount
  useEffect(() => {
    const fetchQuestionBanks = async () => {
      try {
        const userId = localStorage.getItem("userID"); // Retrieve User_ID from local storage
        if (!userId) {
          console.error("User ID not found. Please log in.");
          return;
        }

        const questionBankCollection = collection(db, 'tbl_questionBank');
        const userQuestionBanksQuery = query(questionBankCollection, where("User_ID", "==", userId));
        const querySnapshot = await getDocs(userQuestionBanksQuery);

        const banks = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setQuestionBanks(banks);
      } catch (error) {
        console.error('Error fetching question banks:', error);
      }
    };

    fetchQuestionBanks();
  }, []);

  // Real-time duplicate check for Quiz Name with debounce
  useEffect(() => {
    if (!topicName.trim()) {
      setErrors(prevErrors => ({ ...prevErrors, quizNameDuplicate: '' }));
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      const checkDuplicate = async () => {
        setIsChecking(true);
        try {
          const userId = localStorage.getItem("userID");
          if (!userId) {
            setErrors(prevErrors => ({
              ...prevErrors,
              quizNameDuplicate: 'User not authenticated. Please log in.',
            }));
            setIsChecking(false);
            return;
          }

          const quizzesCollection = collection(db, 'tbl_quizzes');
          const quizQuery = query(
            quizzesCollection,
            where("User_ID", "==", userId),
            where("Quiz_Name", "==", topicName.trim())
          );

          const quizSnapshot = await getDocs(quizQuery);

          if (!quizSnapshot.empty) {
            setErrors(prevErrors => ({
              ...prevErrors,
              quizNameDuplicate: 'This name is already taken. Please choose a different quiz name.',
            }));
          } else {
            setErrors(prevErrors => ({
              ...prevErrors,
              quizNameDuplicate: '',
            }));
          }
        } catch (error) {
          console.error('Error checking quiz name uniqueness:', error);
          setErrors(prevErrors => ({
            ...prevErrors,
            quizNameDuplicate: 'An error occurred while checking the quiz name. Please try again.',
          }));
        } finally {
          setIsChecking(false);
        }
      };

      checkDuplicate();
    }, 500); // Debounce delay of 500ms

    return () => clearTimeout(delayDebounceFn);
  }, [topicName]);

  if (!createquizmodal) return null;

  // Handle Create button click
  const handleCreateQuiz = async () => {
    // Reset previous errors except quizNameDuplicate
    setErrors(prevErrors => ({
      ...prevErrors,
      topicName: '',
      section: '',
      selectedBank: '',
      gradeLevel: '',
    }));

    let hasError = false;
    const newErrors = {};

    // Validation
    if (!topicName.trim()) {
      newErrors.topicName = 'Please enter a topic name.';
      hasError = true;
    }

    if (!section.trim()) {
      newErrors.section = 'Please enter a section.';
      hasError = true;
    }

    if (!selectedBank) {
      newErrors.selectedBank = 'Please choose a question bank.';
      hasError = true;
    }

    if (!gradeLevel) {
      newErrors.gradeLevel = 'Please choose a grade level.';
      hasError = true;
    }

    if (hasError) {
      setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
      return;
    }

    if (errors.quizNameDuplicate) {
      // Prevent creation if there's a duplicate quiz name
      return;
    }

    setIsChecking(true); // Start loading

    try {
      const userId = localStorage.getItem("userID");
      if (!userId) {
        setErrors(prevErrors => ({
          ...prevErrors,
          quizNameDuplicate: 'User not authenticated. Please log in.',
        }));
        setIsChecking(false);
        return;
      }

      const quizzesCollection = collection(db, 'tbl_quizzes');

      // Double-check for duplicate before proceeding
      const quizQuery = query(
        quizzesCollection,
        where("User_ID", "==", userId),
        where("Quiz_Name", "==", topicName.trim())
      );

      const quizSnapshot = await getDocs(quizQuery);

      if (!quizSnapshot.empty) {
        setErrors(prevErrors => ({
          ...prevErrors,
          quizNameDuplicate: 'This name is already taken. Please choose a different quiz name.',
        }));
        setIsChecking(false);
        return;
      }

      // Get the selected question bank name
      const selectedBankObj = questionBanks.find(bank => bank.id === selectedBank);
      const questionBankName = selectedBankObj ? selectedBankObj.Question_Bank_Name : '';

      // If no duplicate, proceed to create quiz
      // Close the modal
      createquiztoggleModal();

      // Navigate to CreateQuizPage with quiz data
      navigate('/Teacher/Library/CreateQuiz', {
        state: {
          quizName: topicName.trim(),
          section: section.trim(),
          gradeLevel: gradeLevel,
          questionBankId: selectedBank,
          questionBankName: questionBankName, // Pass the question bank name
        },
      });

    } catch (error) {
      console.error('Error checking quiz name uniqueness:', error);
      setErrors(prevErrors => ({
        ...prevErrors,
        quizNameDuplicate: 'An error occurred while creating the quiz. Please try again.',
      }));
    } finally {
      setIsChecking(false); // End loading
    }
  };

  // Determine if the Create button should be disabled
  const isCreateDisabled = isChecking || 
    !topicName.trim() || 
    !section.trim() || 
    !selectedBank || 
    !gradeLevel || 
    errors.quizNameDuplicate;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-custom-brownbg w-80 h-auto rounded-lg shadow-2xl transform hover:scale-105 transition-all duration-500 ease-in-out">
        <div className="bg-red text-blue flex items-center justify-between p-4 rounded-t-lg">
          <h2 className="text-yellow text-lg md:text-xl font-bold">Create Quiz</h2>
          <button className="rounded-full p-1" onClick={createquiztoggleModal}>
            <img src={xButton} alt="Close" className="w-4 h-4" />
          </button>
        </div>

        <div className="p-4 flex flex-col gap-4 text-custom-bronwnnav">
          {/* Quiz Name Field */}
          <div className="flex flex-col">
            <label htmlFor="topicName" className="text-base text-red md:text-lg text-left font-semibold">
              Quiz Name:
            </label>
            <input
              id="topicName"
              type="text"
              placeholder="Enter topic name"
              value={topicName}
              onChange={(e) => {
                setTopicName(e.target.value);
                // Clear the error message when user starts typing
                setErrors(prevErrors => ({ ...prevErrors, topicName: '', quizNameDuplicate: '' }));
              }}
              className={`border rounded-md px-4 py-2 ${
                errors.topicName || errors.quizNameDuplicate
                  ? 'border-red-500'
                  : 'border-yellow'
              }`}
            />
            {(errors.topicName || errors.quizNameDuplicate) && (
              <span className="text-red text-sm mt-1">
                {errors.topicName || errors.quizNameDuplicate}
              </span>
            )}
          </div>

          {/* Section Field */}
          <div className="flex flex-col">
            <label htmlFor="section" className="text-base text-red md:text-lg text-left font-semibold">
              Section:
            </label>
            <input
              id="section"
              type="text"
              placeholder="Enter section"
              value={section}
              onChange={(e) => {
                setSection(e.target.value);
                // Clear the error message when user starts typing
                setErrors(prevErrors => ({ ...prevErrors, section: '' }));
              }}
              className={`border rounded-md px-4 py-2 ${
                errors.section ? 'border-red-500' : 'border-yellow'
              }`}
            />
            {errors.section && (
              <span className="text-red-500 text-sm mt-1">
                {errors.section}
              </span>
            )}
          </div>

          {/* Grade Level Field */}
          <div className="flex flex-col">
            <label htmlFor="grade" className="text-base md:text-lg text-red text-left font-semibold">
              Choose grade level:
            </label>
            <select
              id="grade"
              value={gradeLevel}
              onChange={(e) => {
                setGradeLevel(e.target.value);
                // Clear the error message when user selects
                setErrors(prevErrors => ({ ...prevErrors, gradeLevel: '' }));
              }}
              className={`border rounded-md px-4 py-2 ${
                errors.gradeLevel ? 'border-red-500' : 'border-yellow'
              }`}
            >
              <option value="" disabled>Grade level</option>
              <option value="Grade 4">4</option>
              <option value="Grade 5">5</option>
              <option value="Grade 6">6</option>
              <option value="Grade 7">7</option>
              <option value="Grade 8">8</option>
              <option value="Grade 9">9</option>
              <option value="Grade 10">10</option>
            </select>
            {errors.gradeLevel && (
              <span className="text-red-500 text-sm mt-1">
                {errors.gradeLevel}
              </span>
            )}
          </div>

          {/* Question Bank Field */}
          <div className="flex flex-col">
            <label htmlFor="questionType" className="text-base md:text-lg text-red text-left font-semibold">
              Choose question bank:
            </label>
            <select
              id="questionType"
              value={selectedBank}
              onChange={(e) => {
                setSelectedBank(e.target.value);
                // Clear the error message when user selects
                setErrors(prevErrors => ({ ...prevErrors, selectedBank: '' }));
              }}
              className={`border rounded-md px-4 py-2 ${
                errors.selectedBank ? 'border-red-500' : 'border-yellow'
              }`}
            >
              <option value="" disabled>
                Choose question bank
              </option>
              {questionBanks.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.Question_Bank_Name}
                </option>
              ))}
            </select>
            {errors.selectedBank && (
              <span className="text-red-500 text-sm mt-1">
                {errors.selectedBank}
              </span>
            )}
          </div>

          <button
            className={`bg-yellow text-red border border-red font-bold mt-5 py-2 px-6 rounded-md hover:bg-red hover:text-yellow transition-all duration-300 ${
              isCreateDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleCreateQuiz}
            disabled={isCreateDisabled}
          >
            {isChecking ? 'Processing...' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
}
