import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebaseConfig"; // Import firebaseConfig
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore"; // Import Firestore functions
import arrowIcon from "../../../assets/images/arrow-icon.png";
import deleteIcon from "../../../assets/images/deleteRed-icon.png";
import addDarkIcon from "../../../assets/images/addDark-icon.png";

function CreateQuestionBankPage() {
  // Define the desired order of grading levels
  const gradingOrder = [
    "First Grading",
    "Second Grading",
    "Third Grading",
    "Fourth Grading",
  ];

  // State for question containers
  const [questionContainers, setQuestionContainers] = useState([
    {
      id: 1,
      selectedOption: "",
      questionText: "",
      difficultyLevel: "",
      selectedSubTopic: "", // Added for per-question subtopic
      choices: Array(4)
        .fill()
        .map(() => ({ text: "", isCorrect: false })),
      trueFalseAnswer: null,
      yesNoAnswer: null,
      identificationAnswer: "",
    },
  ]);

  // State for question bank details
  const [questionBankName, setQuestionBankName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");

  // States for topics
  const [topicCategories, setTopicCategories] = useState({});
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [topicsError, setTopicsError] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(""); // Global selected topic

  // States for subtopics
  const [subTopics, setSubTopics] = useState([]);
  const [loadingSubTopics, setLoadingSubTopics] = useState(false);
  const [subTopicsError, setSubTopicsError] = useState("");

  // States for name validation
  const [questionBankNameError, setQuestionBankNameError] = useState("");
  const [isCheckingName, setIsCheckingName] = useState(false);

  // State for saving process
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  // Refs for caching fetched topics and subtopics
  const topicsCache = useRef({});
  const subTopicsCache = useRef({});

  // Custom debounce hook
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  // Debounced questionBankName
  const debouncedQuestionBankName = useDebounce(questionBankName, 500);

  // Effect to fetch topics when grade level changes
  useEffect(() => {
    const fetchTopics = async () => {
      if (!gradeLevel) {
        setTopicCategories({});
        setSelectedTopic("");
        setSubTopics([]);
        return;
      }

      // Check if topics for the current gradeLevel are already cached
      if (topicsCache.current[gradeLevel]) {
        setTopicCategories(topicsCache.current[gradeLevel].data);
        setTopicsError(topicsCache.current[gradeLevel].error || "");
        return;
      }

      setLoadingTopics(true);
      setTopicsError("");
      setTopicCategories({});
      setSelectedTopic("");
      setSubTopics([]);
      setSubTopicsError("");

      console.log(`Attempting to fetch topics for Grade_Level: ${gradeLevel}`); // Debugging

      try {
        // Create a query against the collection with a limit
        const q = query(
          collection(db, "tbl_topicCategory"),
          where("Grade_Level", "==", gradeLevel),
          limit(1) // Assuming Grade_Level is unique
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Take the first matching document
          const docData = querySnapshot.docs[0].data();
          console.log("Fetched document data via query:", docData); // Debugging

          if (docData.Topics) {
            setTopicCategories(docData.Topics);
            // Cache the fetched topics
            topicsCache.current[gradeLevel] = { data: docData.Topics, error: "" };
          } else {
            setTopicsError("No topics found for the selected grade level.");
            topicsCache.current[gradeLevel] = { data: {}, error: "No topics found for the selected grade level." };
          }
        } else {
          setTopicsError("No document found for the selected grade level.");
          console.warn(
            `No documents match the Grade_Level "${gradeLevel}".` // Debugging
          );
          topicsCache.current[gradeLevel] = { data: {}, error: "No document found for the selected grade level." };
        }
      } catch (error) {
        console.error("Error fetching topics via query: ", error);
        setTopicsError("Error fetching topics.");
        topicsCache.current[gradeLevel] = { data: {}, error: "Error fetching topics." };
      } finally {
        setLoadingTopics(false);
      }
    };

    fetchTopics();
  }, [gradeLevel]);

  // Effect to fetch subtopics when selectedTopic changes
  useEffect(() => {
    const fetchSubTopics = async () => {
      if (!selectedTopic) {
        setSubTopics([]);
        return;
      }

      // Check if subtopics for the current selectedTopic are already cached
      if (subTopicsCache.current[selectedTopic]) {
        setSubTopics(subTopicsCache.current[selectedTopic].data);
        setSubTopicsError(subTopicsCache.current[selectedTopic].error || "");
        return;
      }

      setLoadingSubTopics(true);
      setSubTopicsError("");
      setSubTopics([]);

      console.log(`Attempting to fetch subtopics for Topic: ${selectedTopic}`); // Debugging

      try {
        // Create a query against the tbl_subTopic collection with a limit
        const q = query(
          collection(db, "tbl_subTopic"),
          where("Main_Topic", "==", selectedTopic),
          limit(1) // Assuming Main_Topic is unique
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Take the first matching document
          const docData = querySnapshot.docs[0].data();
          console.log("Fetched subtopics document data via query:", docData); // Debugging

          if (docData.Sub && Array.isArray(docData.Sub)) {
            setSubTopics(docData.Sub);
            // Cache the fetched subtopics
            subTopicsCache.current[selectedTopic] = { data: docData.Sub, error: "" };
          } else {
            setSubTopicsError("No sub topic/s for the selected topic.");
            subTopicsCache.current[selectedTopic] = { data: [], error: "No sub topic/s for the selected topic." };
          }
        } else {
          setSubTopicsError("No sub topic/s for the selected topic.");
          console.warn(
            `No documents match the Main_Topic "${selectedTopic}".` // Debugging
          );
          subTopicsCache.current[selectedTopic] = { data: [], error: "No sub topic/s for the selected topic." };
        }
      } catch (error) {
        console.error("Error fetching subtopics via query: ", error);
        setSubTopicsError("Error fetching subtopics.");
        subTopicsCache.current[selectedTopic] = { data: [], error: "Error fetching subtopics." };
      } finally {
        setLoadingSubTopics(false);
      }
    };

    fetchSubTopics();
  }, [selectedTopic]);

  // Effect to validate question bank name with debounce
  useEffect(() => {
    if (!debouncedQuestionBankName.trim()) {
      setQuestionBankNameError("");
      return;
    }

    const checkName = async () => {
      setIsCheckingName(true);
      setQuestionBankNameError("");
      try {
        // Retrieve the user ID from local storage
        const userId = localStorage.getItem("userID");
        if (!userId) {
          console.error("User not logged in.");
          setQuestionBankNameError("User not logged in.");
          setIsCheckingName(false);
          return;
        }

        // Query Firestore for existing question bank name for this user
        const q = query(
          collection(db, "tbl_questionBank"),
          where("Question_Bank_Name", "==", debouncedQuestionBankName.trim()),
          where("User_ID", "==", userId),
          limit(1) // Limit to 1 for efficiency
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setQuestionBankNameError("This question bank name is already in use.");
        } else {
          setQuestionBankNameError("");
        }
      } catch (error) {
        console.error("Error checking question bank name: ", error);
        setQuestionBankNameError("Error checking name uniqueness.");
      } finally {
        setIsCheckingName(false);
      }
    };

    checkName();
  }, [debouncedQuestionBankName]);

  // Handler for grade level change
  const handleGradeLevelChange = useCallback((event) => {
    setGradeLevel(event.target.value);
    // Reset selected topic and subtopics when grade level changes
    setSelectedTopic("");
    setSubTopics([]);
  }, []);

  // Handler for topic selection change
  const handleTopicChange = useCallback((event) => {
    setSelectedTopic(event.target.value);
  }, []);

  // Handler for adding a new question container
  const handleAddContainerClick = useCallback(() => {
    setQuestionContainers((prevContainers) => [
      ...prevContainers,
      {
        id: prevContainers.length + 1,
        selectedOption: "",
        questionText: "",
        difficultyLevel: "",
        selectedSubTopic: "", // Initialize subtopic for new question
        choices: Array(4)
          .fill()
          .map(() => ({ text: "", isCorrect: false })),
        trueFalseAnswer: null,
        yesNoAnswer: null,
        identificationAnswer: "",
      },
    ]);
  }, []);

  // Handler for deleting a question container
  const handleDeleteClick = useCallback((index) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this question?"
    );
    if (confirmDelete) {
      setQuestionContainers((prevContainers) =>
        prevContainers.filter((_, i) => i !== index)
      );
    }
  }, []);

  // Handler for question type change
  const handleDropdownChange = useCallback((event, index) => {
    const { value } = event.target;
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[index].selectedOption = value;
      // Reset other fields based on question type
      updatedContainers[index].trueFalseAnswer = null;
      updatedContainers[index].yesNoAnswer = null;
      updatedContainers[index].identificationAnswer = "";
      return updatedContainers;
    });
  }, []);

  // Handler for subtopic selection change per question
  const handleSubTopicChange = useCallback((event, index) => {
    const { value } = event.target;
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[index].selectedSubTopic = value;
      return updatedContainers;
    });
  }, []);

  // Handler for checkbox change in multiple choice
  const handleCheckboxChange = useCallback((event, containerIndex, choiceIndex) => {
    const { checked } = event.target;
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[containerIndex].choices[choiceIndex].isCorrect = checked;
      return updatedContainers;
    });
  }, []);

  // Handler for choice text change in multiple choice
  const handleChoiceTextChange = useCallback((event, containerIndex, choiceIndex) => {
    const { value } = event.target;
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[containerIndex].choices[choiceIndex].text = value;
      return updatedContainers;
    });
  }, []);

  // Handler for adding a new choice in multiple choice
  const handleAddChoiceClick = useCallback((containerIndex) => {
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[containerIndex].choices.push({
        text: "",
        isCorrect: false,
      });
      return updatedContainers;
    });
  }, []);

  // Handler for true/false change
  const handleTrueFalseChange = useCallback((containerIndex, value) => {
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[containerIndex].trueFalseAnswer = value;
      return updatedContainers;
    });
  }, []);

  // Handler for yes/no change
  const handleYesNoChange = useCallback((containerIndex, value) => {
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[containerIndex].yesNoAnswer = value;
      return updatedContainers;
    });
  }, []);

  // Handler for identification answer change
  const handleIdentificationAnswerChange = useCallback((event, index) => {
    const { value } = event.target;
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[index].identificationAnswer = value;
      return updatedContainers;
    });
  }, []);

  // Handler for question text change
  const handleQuestionTextChange = useCallback((event, index) => {
    const { value } = event.target;
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[index].questionText = value;
      return updatedContainers;
    });
  }, []);

  // Handler for difficulty level change
  const handleDifficultyChange = useCallback((event, index) => {
    const { value } = event.target;
    setQuestionContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[index].difficultyLevel = value;
      return updatedContainers;
    });
  }, []);

  // Handler for saving the question bank
  const handleSaveClick = async () => {
    if (isSaving) return; // Prevent multiple clicks
    setIsSaving(true);

    try {
      // Basic validation
      const errors = [];

      if (!questionBankName.trim()) {
        errors.push("Please enter a question bank name.");
      }

      if (questionBankNameError) {
        errors.push(
          "Please resolve the question bank name error before saving."
        );
      }

      if (!gradeLevel) {
        errors.push("Please select a grade level.");
      }

      if (!selectedTopic) {
        errors.push("Please select a topic for the question bank.");
      }

      // Validate each question
      questionContainers.forEach((container, index) => {
        if (!container.selectedOption) {
          errors.push(`Please select a question type for question ${index + 1}.`);
        } else {
          if (!container.questionText.trim()) {
            errors.push(
              `Please enter the question text for question ${index + 1}.`
            );
          }
          if (!container.difficultyLevel) {
            errors.push(
              `Please select a difficulty level for question ${index + 1}.`
            );
          }
          if (!container.selectedSubTopic) {
            errors.push(
              `Please select a subtopic for question ${index + 1}.`
            );
          }

          if (container.selectedOption === "Multiple Choice") {
            const filledChoices = container.choices.filter(
              (choice) => choice.text.trim() !== ""
            );
            if (filledChoices.length < 2) {
              errors.push(
                `Please provide at least two choices with text for question ${index + 1}.`
              );
            }
            if (!container.choices.some((choice) => choice.isCorrect)) {
              errors.push(
                `Please select at least one correct answer for multiple choice question ${index + 1}.`
              );
            }
          } else if (
            container.selectedOption === "True or False" ||
            container.selectedOption === "Yes or No"
          ) {
            const selectedAnswer =
              container.selectedOption === "True or False"
                ? container.trueFalseAnswer
                : container.yesNoAnswer;

            if (!selectedAnswer) {
              errors.push(
                `Please select an answer for the ${container.selectedOption} question ${index + 1}.`
              );
            }
          } else if (container.selectedOption === "Identification") {
            if (!container.identificationAnswer.trim()) {
              errors.push(
                `Please provide an answer for the identification question ${index + 1}.`
              );
            }
          }
        }
      });

      if (errors.length > 0) {
        alert(errors.join("\n"));
        setIsSaving(false);
        return;
      }

      // Retrieve the user ID from local storage
      const userId = localStorage.getItem("userID");
      if (!userId) {
        console.error("User not logged in.");
        alert("User not logged in.");
        setIsSaving(false);
        return;
      }

      // Add the question bank to Firestore with user ID
      const questionBankDocRef = await addDoc(
        collection(db, "tbl_questionBank"),
        {
          Question_Bank_Name: questionBankName.trim(),
          Grade_Level: gradeLevel,
          Topic: selectedTopic, // Associate global topic with the question bank
          Creation_Date: serverTimestamp(),
          Modified_Date: serverTimestamp(),
          User_ID: userId, // Associate question bank with the logged-in user
        }
      );

      const questionBankId = questionBankDocRef.id;

      // Add each question to Firestore
      for (const container of questionContainers) {
        if (!container.selectedOption) {
          continue; // This should not happen due to prior validation
        }

        const questionDocRef = await addDoc(
          collection(db, "tbl_question"),
          {
            Question_Bank_ID: questionBankId,
            Question_Type: container.selectedOption,
            Question_Text: container.questionText.trim(),
            Difficulty_Level: container.difficultyLevel,
            Topic: selectedTopic, // Associate global topic with the question
            Subtopic: container.selectedSubTopic, // Associate subtopic with the question
          }
        );

        // Handle different question types
        if (container.selectedOption === "Multiple Choice") {
          for (let i = 0; i < container.choices.length; i++) {
            if (container.choices[i].text.trim() === "") continue; // Skip empty choices
            await addDoc(collection(db, "tbl_choices"), {
              Question_ID: questionDocRef.id,
              Choice_Text: container.choices[i].text.trim(),
              Is_Correct: container.choices[i].isCorrect,
            });
          }
        } else if (
          container.selectedOption === "True or False" ||
          container.selectedOption === "Yes or No"
        ) {
          const selectedAnswer =
            container.selectedOption === "True or False"
              ? container.trueFalseAnswer
              : container.yesNoAnswer;

          const choices =
            container.selectedOption === "True or False"
              ? ["True", "False"]
              : ["Yes", "No"];
          for (let i = 0; i < choices.length; i++) {
            await addDoc(collection(db, "tbl_choices"), {
              Question_ID: questionDocRef.id,
              Choice_Text: choices[i],
              Is_Correct: choices[i] === selectedAnswer,
            });
          }
        } else if (container.selectedOption === "Identification") {
          await addDoc(collection(db, "tbl_answers"), {
            Question_ID: questionDocRef.id,
            Answer_text: container.identificationAnswer.trim(),
          });
        }
      }

      // Navigate to the Question Bank library after saving
      navigate("/Teacher/Library/QuestionBank");
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("An error occurred while saving the question bank.");
    } finally {
      setIsSaving(false);
    }
  };

  // Handler for cancel button
  const handleCancelClick = useCallback(() => {
    const confirmCancel = window.confirm("Are you sure you want to cancel?");
    if (confirmCancel) {
      navigate("/Teacher/Library/QuestionBank");
    }
    // If user selects "No", do nothing and stay on the current page
  }, [navigate]);

  return (
    <div className="flex-1 min-h-screen bg-custom-brownbg pb-4 md:pb-10">
      <header className="flex items-center shadow-custom-darkblue h-16 md:h-20">
        <div className="flex p-2 md:p-4 text-lg md:text-2xl items-center font-bold text-custom-brownnav">
          <h1>QUESTION BANK</h1>
          <img
            src={arrowIcon}
            alt="Arrow Page"
            className="w-3 h-5 mx-5 md:w-4 md:h-7"
          />
          <h1>CREATE</h1>
        </div>
      </header>

      {/* Main container */}
      <div className="flex flex-col mt-5 md:mt-6 px-4">
        {/* QUESTION BANK NAME */}
        <div className="flex flex-col w-full items-start gap-2">
          <label className="text-custom-brownnav text-left text-lg md:text-xl font-semibold mb-2">
            Question Bank Name
          </label>
          <input
            type="text"
            className={`w-full md:w-1/2 border ${
              questionBankNameError ? "border-red-500" : "border-blue"
            } rounded-md p-2 text-base focus:outline-none focus:ring-2 focus:ring-custom-blue`}
            placeholder="Enter question bank name"
            value={questionBankName}
            onChange={(e) => setQuestionBankName(e.target.value)}
          />
          {isCheckingName && (
            <p className="text-sm text-gray-500">Checking name availability...</p>
          )}
          {questionBankNameError && (
            <p className="text-sm text-red">{questionBankNameError}</p>
          )}
        </div>

        {/* GRADE LEVEL and TOPIC SELECTION */}
        <div className="flex flex-col md:flex-row w-full items-start gap-4 mt-4">
          {/* GRADE LEVEL */}
          <div className="flex flex-col w-full md:w-1/2 items-start gap-2">
            <label className="text-custom-brownnav text-left text-lg md:text-xl font-semibold mb-2">
              Grade Level
            </label>
            <select
              className="w-full md:w-full text-lg border border-blue rounded-md p-2  focus:outline-none focus:ring-2 focus:ring-custom-blue"
              value={gradeLevel}
              onChange={handleGradeLevelChange}
            >
              <option value="" disabled>
                Select grade level
              </option>
              <option value="Grade 4">Grade 4</option>
              <option value="Grade 5">Grade 5</option>
              <option value="Grade 6">Grade 6</option>
              <option value="Grade 7">Grade 7</option>
              <option value="Grade 8">Grade 8</option>
              <option value="Grade 9">Grade 9</option>
              <option value="Grade 10">Grade 10</option>
            </select>
          </div>

          {/* SELECT TOPIC */}
          {gradeLevel && (
            <div className="flex flex-col  md:w-1/2 items-start gap-2">
              <label className="text-custom-brownnav  text-left text-lg md:text-xl font-semibold mb-2">
                Select Topic
              </label>
              {loadingTopics ? (
                <p>Loading topics...</p>
              ) : topicsError ? (
                <p className="text-red py-2 text-lg font-semibold">{topicsError}</p>
              ) : Object.keys(topicCategories).length > 0 ? (
                <select
                  className=" w-full  border border-blue rounded-md p-2 text-base focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  value={selectedTopic}
                  onChange={handleTopicChange}
                >
                  <option value="" disabled>
                    Select a topic
                  </option>
                  {gradingOrder.map((grading) =>
                    topicCategories[grading] && (
                      <optgroup label={grading} key={grading}>
                        {topicCategories[grading].map((topic, idx) => (
                          <option value={topic} key={idx}>
                            {topic}
                          </option>
                        ))}
                      </optgroup>
                    )
                  )}
                </select>
              ) : (
                <p className="text-red-500">No topics available.</p>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Render each question container */}
      {questionContainers.map((container, index) => (
        <div
          key={index}
          className="bg-white p-4 md:p-6 rounded-md shadow-custom-darkblue mx-4 md:mx-4 mt-4 flex flex-col gap-4"
        >
          {/* QUESTION TYPE */}
          <div className="flex flex-wrap gap-4 text-xs md:text-lg items-center">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name={`questionType-${index}`}
                value="Multiple Choice"
                onChange={(event) => handleDropdownChange(event, index)}
                checked={container.selectedOption === "Multiple Choice"}
              />
              Multiple Choice
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name={`questionType-${index}`}
                value="True or False"
                onChange={(event) => handleDropdownChange(event, index)}
                checked={container.selectedOption === "True or False"}
              />
              True or False
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name={`questionType-${index}`}
                value="Yes or No"
                onChange={(event) => handleDropdownChange(event, index)}
                checked={container.selectedOption === "Yes or No"}
              />
              Yes or No
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name={`questionType-${index}`}
                value="Identification"
                onChange={(event) => handleDropdownChange(event, index)}
                checked={container.selectedOption === "Identification"}
              />
              Identification
            </label>
          </div>

          {/* Question Text with Numbering */}
          <div className="flex items-start gap-2">
            <span className="text-xl font-bold">{index + 1}.</span>
            <input
              type="text"
              className="border border-blue p-2 rounded-md flex-1 focus:outline-none focus:ring-2 focus:ring-custom-blue"
              placeholder="Type your question here..."
              value={container.questionText}
              onChange={(e) => handleQuestionTextChange(e, index)}
            />
          </div>

          {/* Difficulty Level and Subtopic Selection */}
          <div className="flex flex-wrap pl-6  md:gap-4">
            {/* Difficulty Level */}
            <select
              className="text-xs border  border-blue md:text-lg rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-custom-blue"
              value={container.difficultyLevel}
              onChange={(e) => handleDifficultyChange(e, index)}
            >
              <option value="" disabled>
                Select difficulty level
              </option>
              <option value="Easy">Easy</option>
              <option value="Medium">Medium</option>
              <option value="Hard">Hard</option>
            </select>

            {/* Subtopic Selection */}
            {selectedTopic && (
              <div className="flex flex-1 justify-center flex-col gap-2 mt-2 md:mt-0">
                {loadingSubTopics ? (
                  <p>Loading subtopics...</p>
                ) : subTopicsError ? (
                  <p className="text-red text-left text-lg font-semibold">{subTopicsError}</p>
                ) : subTopics.length > 0 ? (
                  <select
                    className="border text-lg border-blue rounded-md p-2  focus:outline-none focus:ring-2 focus:ring-custom-blue"
                    value={container.selectedSubTopic}
                    onChange={(e) => handleSubTopicChange(e, index)}
                  >
                    <option value="" disabled>
                      Select a subtopic
                    </option>
                    {subTopics.map((sub, idx) => (
                      <option value={sub} key={idx}>
                        {sub}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="text-red-500">No subtopics available.</p>
                )}
              </div>
            )}
          </div>

          {/* Conditional Rendering based on question type */}
          {container.selectedOption === "Multiple Choice" && (
            <div className="rounded-md flex flex-col gap-4">
              <div className="flex md:items-center flex-col md:flex-row md:gap-3">
                <p className="text-base text-left md:text-lg font-semibold">
                  Choices
                </p>
                <p className="text-xs text-left md:text-base">
                  (select checkbox for correct answer)
                </p>
              </div>
              <div className="grid grid-cols-2 md:grid-row-2 gap-2">
                {container.choices.map((choice, choiceIndex) => (
                  <div
                    key={choiceIndex}
                    className="flex items-center gap-1 w-full"
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4 md:h-5 md:w-5"
                      checked={choice.isCorrect}
                      onChange={(event) =>
                        handleCheckboxChange(event, index, choiceIndex)
                      }
                    />
                    <input
                      type="text"
                      className="flex-1 border border-blue p-2 rounded-md w-full md:text-base focus:outline-none focus:ring-2 focus:ring-custom-blue"
                      placeholder={`Choice ${choiceIndex + 1}`}
                      value={choice.text}
                      onChange={(event) =>
                        handleChoiceTextChange(event, index, choiceIndex)
                      }
                    />
                  </div>
                ))}
              </div>
              <button
                className="flex gap-2 ml-6 items-center bg-white text-yellow font-bold border border-yellow p-1 rounded-md self-start"
                onClick={() => handleAddChoiceClick(index)}
              >
                <img src={addDarkIcon} alt="Add Icon" className="w-4 h-4" />
                Add Choice
              </button>
            </div>
          )}

          {/* True or False */}
          {container.selectedOption === "True or False" && (
            <div className="rounded-md mt-4 flex flex-col gap-4">
              <div className="grid grid-cols-2 md:grid-cols gap-2">
                {["True", "False"].map((option, choiceIndex) => (
                  <div
                    key={choiceIndex}
                    className="flex items-center gap-1 w-full"
                  >
                    <input
                      type="radio"
                      name={`trueFalse-${index}`}
                      className="h-4 w-4 md:h-5 md:w-5"
                      checked={container.trueFalseAnswer === option}
                      onChange={() =>
                        handleTrueFalseChange(index, option)
                      }
                    />
                    <label className="flex-1 border border-blue p-2 rounded-md w-full md:text-base">
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Yes or No */}
          {container.selectedOption === "Yes or No" && (
            <div className="rounded-md mt-4 flex flex-col gap-4">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-2">
                {["Yes", "No"].map((option, choiceIndex) => (
                  <div
                    key={choiceIndex}
                    className="flex items-center gap-1 w-full"
                  >
                    <input
                      type="radio"
                      name={`yesNo-${index}`}
                      className="h-4 w-4 md:h-5 md:w-5"
                      checked={container.yesNoAnswer === option}
                      onChange={() =>
                        handleYesNoChange(index, option)
                      }
                    />
                    <label className="flex-1 border border-blue p-2 rounded-md w-full md:text-base">
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Identification */}
          {container.selectedOption === "Identification" && (
            <div className="rounded-md mt-4 pl-6 flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <p className="text-base md:text-lg font-semibold">Answer</p>
              </div>
              <input
                type="text"
                className="border border-blue p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue"
                placeholder="Type the correct answer here..."
                value={container.identificationAnswer}
                onChange={(e) =>
                  handleIdentificationAnswerChange(e, index)
                }
              />
            </div>
          )}

          {/* Delete Button */}
          <div className="flex gap-4 pl-6">
            <button
              className="flex bg-white border border-red rounded-md px-4 py-1 gap-4 text-red font-bold self-end"
              onClick={() => handleDeleteClick(index)}
            >
              <img src={deleteIcon} alt="Delete Icon" className="w-4 h-6" />
              Delete
            </button>
          </div>
        </div>
      ))}

      {/* Add and Save Buttons */}
      <div className="flex gap-4 p-4">
        <button
          className="text-lg ml-auto bg-white px-5 hover:bg-blue hover:text-white text-blue border border-blue font-semibold rounded-md"
          onClick={handleAddContainerClick}
        >
          Add Question
        </button>
        <button
          className={`text-lg bg-white px-5 text-yellow border border-yellow hover:bg-yellow hover:text-white font-bold rounded-md ${
            questionBankNameError || isCheckingName || isSaving
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={handleSaveClick}
          disabled={questionBankNameError || isCheckingName || isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
        <button
          className="text-lg bg-white px-5 text-red border border-red hover:bg-red hover:text-white font-semibold rounded-md"
          onClick={handleCancelClick} // Added onClick handler
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default CreateQuestionBankPage;
