// EditQuizPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { getFirestore, doc, setDoc, collection, getDocs, query, where } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";

function EditQuizPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedQuestions, quizName, gradeLevel, section, creationTime, questionBankName, questionBankId } = location.state || { selectedQuestions: [] };

  const db = getFirestore();

  const userId = localStorage.getItem("userID");  // Retrieve User_ID from local storage

  const [pointsPerQuestion, setPointsPerQuestion] = useState(
    selectedQuestions.reduce((acc, question) => ({ ...acc, [question.id]: 1 }), {})
  );
  const [timersPerQuestion, setTimersPerQuestion] = useState(
    selectedQuestions.reduce((acc, question) => ({ ...acc, [question.id]: "30 seconds" }), {})
  );
  const [totalPoints, setTotalPoints] = useState(0);
  const [selectedDifficulty, setSelectedDifficulty] = useState("All");
  const [easyAccuracy, setEasyAccuracy] = useState("");
  const [mediumAccuracy, setMediumAccuracy] = useState("");
  const [hardAccuracy, setHardAccuracy] = useState("");
  const [topic, setTopic] = useState(''); // New state variable for Topic

  useEffect(() => {
    const total = Object.values(pointsPerQuestion).reduce((acc, curr) => acc + curr, 0);
    setTotalPoints(total);
  }, [pointsPerQuestion]);

  useEffect(() => {
    const fetchTopic = async () => {
      if (!questionBankName || !userId) {
        console.error("Question bank name or user ID not provided.");
        return;
      }

      const questionBankRef = collection(db, "tbl_questionBank");
      const q = query(questionBankRef, where("Question_Bank_Name", "==", questionBankName), where("User_ID", "==", userId));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        setTopic(docData.Topic || '');
      } else {
        console.error("No question bank found with the given name.");
      }
    };

    fetchTopic();
  }, [questionBankName, userId]);

  const handlePointSelection = (points, questionId) => {
    setPointsPerQuestion(prev => ({
      ...prev,
      [questionId]: parseInt(points),
    }));
  };

  const handleTimerSelection = (timer, questionId) => {
    setTimersPerQuestion(prev => ({
      ...prev,
      [questionId]: timer,
    }));
  };

  const handleSaveQuiz = async (status) => {
    if (!userId) {
      console.error("User ID not found. Please log in.");
      return;
    }

    const quizDocRef = doc(collection(db, "tbl_quizzes"));

    const questionsData = selectedQuestions.map((question) => ({
      Question_Text: question.Question_Text,
      Question_Type: question.Question_Type,
      Difficulty_Level: question.Difficulty_Level,
      Choices: question.Choices,
      Points: pointsPerQuestion[question.id],
      Timer: timersPerQuestion[question.id],
      Subtopic: question.Subtopic || "N/A",
    }));

    await setDoc(quizDocRef, {
      User_ID: userId,  // Associate quiz with the logged-in user
      Quiz_Name: quizName,
      Section: section,  // Include section in the quiz data
      Grade_Level: gradeLevel,
      Creation_Date: serverTimestamp(),
      Status: status,
      Number_Of_Questions: selectedQuestions.length,
      Questions: questionsData,
      Accuracy: {
        easy: parseFloat(easyAccuracy) || 0,
        medium: parseFloat(mediumAccuracy) || 0,
        hard: parseFloat(hardAccuracy) || 0,
      },
      Question_Bank_Name: questionBankName || "", // Include question bank name
      Topic: topic || "", // Include topic
      Question_Bank_ID: questionBankId, // Add this line
    });

    navigate("/Teacher/Library");
  };

  const renderQuestionsByDifficulty = (questions, difficulty) => {
    return questions
      .filter(question => question.Difficulty_Level === difficulty)
      .map((question, index) => (
        <div key={question.id} className="bg-custom-brownbg p-2 md:p-4 rounded-md shadow-custom-darkblue flex flex-col gap-4">
          
          <div className="flex gap-4 md:items-center flex-col md:flex-row">
            <span className="text-gray-900 bg-white text-left border border-1 md:p-2 border-black p-1 rounded-md font-semibold text-base md:text-lg">
              {index + 1}. {question.Question_Type} - {question.Difficulty_Level}
            </span>

            <div className="flex flex-row w-full md:w-1/2 gap-2">
              <select className="w-1/2 p-1 md:p-2 text-xs md:text-lg border border-gray-900 rounded-md"
                      value={pointsPerQuestion[question.id] || 1}
                      onChange={e => handlePointSelection(e.target.value, question.id)}>
                <option value="1">1 point</option>
                <option value="2">2 points</option>
                <option value="3">3 points</option>
              </select>

              <select className="w-1/2 p-1 md:p-2 text-xs md:text-lg border border-gray-900 rounded-md"
                      value={timersPerQuestion[question.id] || "30 seconds"}
                      onChange={e => handleTimerSelection(e.target.value, question.id)}>
                <option value="15 seconds">15 seconds</option>
                <option value="30 seconds">30 seconds</option>
                <option value="1 minute">1 min</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-gray-900 text-left font-semibold text-base md:text-xl">
              {question.Question_Text}
            </span>
            <span className="text-gray-600 text-left font-semibold text-base md:text-lg">
              {question.Question_Type === 'Identification' ? 'Answer' : 'Choices'}
            </span>
          </div>

          <div className="grid grid-cols-2 md:grid-row-2 md:gap-2 gap-x-6 gap-y-3">
            {question.Choices && question.Choices.map((choice, choiceIndex) => (
              <span 
                key={choiceIndex} 
                className={`flex items-center text-gray-900 border-2 border-gray-900 rounded-md p-1 text-left font-semibold text-sm md:text-xl ${choice.Is_Correct ? 'border-green-600 bg-green-300' : ''}`}
              >
                <span className="mr-2">
                  {String.fromCharCode(65 + choiceIndex)}.
                </span>
                {choice.Choice_Text}
              </span>
            ))}
          </div>
        </div>
      ));
  };

  return (
    <div className="flex flex-col gap-4 min-h-screen pt-5 pb-5 bg-custom-brownbg md:p-6 md:pb-10">
      {/* General Quiz Information */}
      <div className="bg-blue border-2 border-blue p-6 md:p-6 rounded-md shadow-custom-darkblue mx-2 md:mx-6 flex flex-col gap-4">
        <div className="flex flex-row items-center gap-4">
          <div className="md:flex-col md:flex md:items-center md:justify-center">
            <div className="w-24 h-24 md:w-24 md:h-24 bg-gray-200 rounded-md flex items-center justify-center">
              <span className="text-gray-500">Image Here</span>
            </div>
            <div className="hidden md:flex md:flex-col md:items-center md:mt-2">
              <button className="bg-white border text-red hover:bg-red hover:text-white hover:border hover:border-white p-1 text-sm md:text-xs font-bold rounded-md shadow-md hover:bg-yellow-600">
                Upload Image
              </button>
            </div>
          </div>

          <div className="flex-1 flex flex-col md:flex-row md:items-start md:justify-between md:w-full">
            <div className="flex-1 flex flex-col items-start">
              <h2 className="text-white text-base md:text-2xl font-bold">
                {quizName}
              </h2>
              <span className="text-white text-sm md:text-xl">
                {selectedQuestions.length} questions
              </span>
              <span className="text-white text-xs md:text-lg">
                {gradeLevel}
              </span>
              <span className="text-white text-xs md:text-lg">
                Section: {section}
              </span>
              <span className="text-white text-xs md:text-lg">
                {creationTime}
              </span>
              <span className="text-white text-xs md:text-lg">
                Question Bank: {questionBankName || "N/A"}
              </span>
              <span className="text-white text-xs md:text-lg">
                Topic: {topic || "N/A"}
              </span>
            </div>

            <div className="hidden md:flex md:flex-row md:gap-4 md:items-end">
              <button
                className="bg-white border text-red hover:bg-red hover:text-white hover:border hover:border-white px-4 py-2 text-sm md:text-base font-bold rounded-md shadow-md hover:bg-yellow-600"
                onClick={() => handleSaveQuiz("draft")}
              >
                Draft
              </button>
              <button
                className="bg-white border text-red hover:bg-red hover:text-white hover:border hover:border-white px-4 py-2 text-sm md:text-base font-bold rounded-md shadow-md hover:bg-yellow-600"
                onClick={() => handleSaveQuiz("published")}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex px-6 items-baseline gap-4">
        <h2 className="text-blue text-left text-xl md:text-2xl font-bold">
          {selectedQuestions.length} Questions
        </h2>
        <span className="text-blue font-semibold text-base md:text-lg">
          ({totalPoints} points)
        </span>

        <select
          className="p-1 md:p-2 text-xs md:text-base border border-blue text-red rounded-md"
          value={selectedDifficulty}
          onChange={(e) => setSelectedDifficulty(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Easy">Easy</option>
          <option value="Medium">Medium</option>
          <option value="Hard">Hard</option>
        </select>
      </div>

      {/* Display Containers Based on Selected Difficulty */}
      {selectedDifficulty === "All" || selectedDifficulty === "Easy" ? (
        <div className="bg-white border-2 border-yellow p-6 md:p-6 rounded-md shadow-custom-darkblue mx-2 md:mx-6 flex flex-col gap-4">
          <div className="flex">
            <h2 className="text-yellow text-left text-xl md:text-2xl font-bold">Easy Questions</h2>
            <div className="ml-auto">
              <div className="flex gap-2">
                <h2 className="text-yellow text-left text-xl md:text-2xl font-bold">Threshold:</h2>
                <input
                  type="number"
                  value={easyAccuracy}
                  onChange={(e) => setEasyAccuracy(e.target.value)}
                  className="p-1 text-base border border-yellow text-yellow rounded-md w-12"
                  placeholder=""
                />
              </div>
            </div>
          </div>  
          {renderQuestionsByDifficulty(selectedQuestions, 'Easy')}
        </div>
      ) : null}

      {selectedDifficulty === "All" || selectedDifficulty === "Medium" ? (
        <div className="bg-white border-2 border-blue p-6 md:p-6 rounded-md shadow-custom-darkblue mx-2 md:mx-6 flex flex-col gap-4">
          <div className="flex">
            <h2 className="text-blue text-left text-xl md:text-2xl font-bold">Medium Questions</h2>
            <div className="ml-auto">
              <div className="flex gap-2">
                <h2 className="text-blue text-left text-xl md:text-2xl font-bold">Threshold:</h2>
                <input
                  type="number"
                  value={mediumAccuracy}
                  onChange={(e) => setMediumAccuracy(e.target.value)}
                  className="p-1 text-base border border-blue text-blue rounded-md w-12"
                  placeholder=""
                />
              </div>
            </div>
          </div> 
          {renderQuestionsByDifficulty(selectedQuestions, 'Medium')}
        </div>
      ) : null}

      {selectedDifficulty === "All" || selectedDifficulty === "Hard" ? (
        <div className="bg-white border-2 border-red p-6 md:p-6 rounded-md shadow-custom-darkblue mx-2 md:mx-6 flex flex-col gap-4">
          <div className="flex">
            <h2 className="text-red text-left text-xl md:text-2xl font-bold">Hard Questions</h2>
            <div className="ml-auto">
              <div className="flex gap-2">
                <h2 className="text-red text-left text-xl md:text-2xl font-bold">Threshold:</h2>
                <input
                  type="number"
                  value={hardAccuracy}
                  onChange={(e) => setHardAccuracy(e.target.value)}
                  className="p-1 text-base border border-red text-red rounded-md w-12"
                  placeholder=""
                />
              </div>
            </div>
          </div>  
          {renderQuestionsByDifficulty(selectedQuestions, 'Hard')}
        </div>
      ) : null}

      <div className="flex justify-end gap-3 p-4 md:p-6">
        <button
          className="bg-yellow text-red border border-red hover:bg-red hover:text-yellow px-4 py-2 text-lg font-bold rounded-md shadow-md"
          onClick={() => handleSaveQuiz("published")}
        >
          Publish Quiz
        </button>

        <button
          className="bg-red text-yellow border-red hover:bg-yellow hover:text-red hover:border hover:border-red px-4 py-2 text-lg font-bold rounded-md shadow-md"
          onClick={() => navigate(-1)} // Navigate back to the previous page
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default EditQuizPage;
