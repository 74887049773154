import { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import editDarkIcon from "../../assets/images/editDark-icon.png";

function StudentProfilePage() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const userID = localStorage.getItem("userID");
  const role = localStorage.getItem("role");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const collectionName = role === "Teacher" ? "tbl_teacher" : "tbl_students";
        const userDocRef = doc(db, collectionName, userID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setProfile(userDoc.data());
        } else {
          console.error("No profile found for the user.");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userID, role]);

  if (loading) {
    return <div className="text-center text-blue text-xl">Loading Profile...</div>;
  }

  if (!profile) {
    return <div className="text-center text-red text-xl">Failed to load profile.</div>;
  }

  return (
    <div className="flex-1 min-h-screen bg-custom-brownbg pb-4 md:pb-10">
      <header className="flex items-center shadow-custom-darkblue h-16 md:h-20">
        <div className="flex p-2 md:p-4 text-lg md:text-2xl font-bold text-custom-brownnav">
          <h1>PROFILE</h1>
        </div>
      </header>

      {/* Profile Section */}
      <div className="flex flex-col items-center p-6 md:p-8">
        {/* Profile Container */}
        <div className="bg-blue p-6 rounded-md shadow-custom-yellow shadow-md w-full md:w-3/4 lg:w-1/2">
          <div className="flex items-center justify-between">
            {/* Profile Image */}
            <div className="flex items-center gap-9">
              <div className="w-24 h-24 rounded-md border-4 border-blue-500 bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500 text-sm">Image</span>
              </div>
              <div>
                <h2 className="text-3xl text-left font-bold text-white">ROLE</h2>
                <p className="text-2xl text-left text-white">{profile.Role}</p>
              </div>
            </div>
            <button className="p-2 bg-white text-sm text-blue rounded-md border border-yellow hover:bg-yellow hover:text-blue">
              Edit Image
            </button>
          </div>
        </div>

        {/* Form Section */}
        <div className="bg-white p-6 mt-6 rounded-md shadow-md shadow-custom-yellow w-full md:w-3/4 lg:w-1/2">
          <div className="grid grid-cols-1 gap-4">
            {/* Username */}
            <div className="flex items-center gap-4">
              <label className="text-left w-32 text-blue text-sm font-medium">Username</label>
              <input
                type="text"
                value={profile.Username}
                readOnly
                className="p-2 w-full rounded-md border border-yellow shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              />
              <button className="p-1">
                <img src={editDarkIcon} alt="Edit" className="w-5 h-4" />
              </button>
            </div>

            {/* First Name */}
            <div className="flex items-center gap-4">
              <label className="w-32 text-left text-blue text-sm font-medium">First name</label>
              <input
                type="text"
                value={profile.First_Name}
                readOnly
                className="p-2 block w-full rounded-md border border-yellow shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              />
              <button className="p-1">
                <img src={editDarkIcon} alt="Edit" className="w-5 h-4" />
              </button>
            </div>

            {/* Last Name */}
            <div className="flex items-center gap-4">
              <label className="w-32 text-left text-blue text-sm font-medium">Last name</label>
              <input
                type="text"
                value={profile.Last_Name}
                readOnly
                className="p-2 block w-full rounded-md border border-yellow shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              />
              <button className="p-1">
                <img src={editDarkIcon} alt="Edit" className="w-5 h-4" />
              </button>
            </div>

            {/* Email */}
            <div className="flex items-center gap-4">
              <label className="w-32 text-left text-blue text-sm font-medium">Email</label>
              <input
                type="email"
                value={profile.Email}
                readOnly
                className="p-2 block w-full rounded-md border border-yellow shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              />
              <button className="p-1">
                <img src={editDarkIcon} alt="Edit" className="w-5 h-4" />
              </button>
            </div>

            {/* Password */}
            <div className="flex items-center gap-4">
              <label className="w-32 text-left text-blue text-sm font-medium">Password</label>
              <input
                type="password"
                value="********" // Masked for security
                readOnly
                className="p-2 block w-full rounded-md border border-yellow shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              />
              <button className="p-1">
                <img src={editDarkIcon} alt="Edit" className="w-5 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentProfilePage;
