import React from 'react';
import viewarrowbutton from "../../assets/images/viewArrowDark-icon.png";

function StudentActivityPage() {
    return (
        <div className="flex-1 min-h-screen bg-custom-brownbg pb-4 md:pb-10">
            {/* Header */}
            <header className="flex items-center shadow-custom-darkblue h-16 md:h-20">
                <div className="flex p-2 md:p-4 text-lg md:text-2xl font-bold text-darkp">
                    <h1>Activity</h1>
                </div>
            </header>

            {/* Main Content */}
            <div className="p-4 md:p-6 space-y-8">
                {/* Top Container: Recent Activities */}
                <div className="flex flex-col md:flex-row flex-1 w-full items-center gap-2">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search..."

            className="w-full md:w-1/4 p-2 text-red text-sm md:text-base border border-blue rounded-md"
          />

          {/* Dropdowns */}
          <div className="flex flex-row md:flex-row w-full md:w-1/2 gap-2 mt-2 md:mt-0">
            {/* Search Option Dropdown */}
            <select
      
              className="w-full md:w-1/2 p-1 md:p-2 text-xs text-red md:text-base border border-blue rounded-md"
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="All">All</option>
              <option value="by bank name">By question bank name</option>
            </select>
            </div>
            </div>
                <div className="bg-gradient-to-r from-yellow-100 to-yellow-300 rounded-lg shadow-lg">
                    <h2 className="text-3xl text-left font-bold text-darkp mb-6">Recent Activities</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 bg-f">
                        {[
                            {
                                title: "Kasaysayan ng Pilipinas",
                                type: "Completed",
                                count: 15,
                                accuracy: "85%",
                                color: "bg-amber-500",
                            },
                            {
                                title: "Pag-usbong ng Kabihasnang Asyano",
                                type: "Completed",
                                count: 12,
                                accuracy: "90%",
                                color: "bg-green-500",
                            },
                            {
                                title: "Mga Bayani ng Himagsikan",
                                type: "Completed",
                                count: 8,
                                accuracy: "86%",
                                color: "bg-teal-500",
                            },
                            {
                                title: "Kolonisasyon at Paglaban",
                                type: "Completed",
                                count: 20,
                                accuracy: "92%",
                                color: "bg-fuchsia-500",
                            },
                            {
                                title: "Kultura at Tradisyon sa Pilipinas",
                                type: "Completed",
                                count: 10,
                                accuracy: "78%",
                                color: "bg-pink",
                            },
                        ].map((activity, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-md flex flex-col hover:shadow-xl transition duration-300 ease-in-out">
                                <div className="flex items-center mb-4">
                                    <div className={`w-3 h-3 rounded-full ${activity.color} mr-3`}></div>
                                    <div className="font-semibold text-sm">{activity.type}</div>
                                </div>
                                <h3 className="text-xl font-bold mb-2 text-darkp">{activity.title}</h3>
                                <p className="text-gray-600 text-sm mb-4">
                                    {activity.count} Questions
                                </p>
                                {activity.accuracy !== "N/A" && (
                                    <div className={`mt-auto text-white text-center py-2 w-full rounded-md ${activity.color} font-bold`}>
                                        {activity.accuracy} accuracy
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Bottom Container: Other Activities */}
                <div className="bg-gradient-to-r from-blue-100 to-blue-300 rounded-lg shadow-lg p-8">
                    <h2 className="text-3xl text-left font-bold text-darkp mb-6">Other Activities</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 bg-">
                        {[
                            {
                                title: "Epekto ng Kolonyalismo",
                                type: "Completed",
                                count: 14,
                                accuracy: "88%",
                                color: "bg-orange-400",
                            },
                            {
                                title: "Pagbabagong Pangkabuhayan",
                                type: "In Progress",
                                count: 9,
                                accuracy: "N/A",
                                color: "bg-yellow-500",
                            },
                            {
                                title: "Mga Pangulo ng Pilipinas",
                                type: "Completed",
                                count: 18,
                                accuracy: "81%",
                                color: "bg-green-500",
                            },
                            {
                                title: "Pamumuhay ng Sinaunang Pilipino",
                                type: "Completed",
                                count: 13,
                                accuracy: "87%",
                                color: "bg-red-500",
                            },
                            {
                                title: "Kalakalang Galyon",
                                type: "Completed",
                                count: 10,
                                accuracy: "82%",
                                color: "bg-blue-500",
                            },
                            {
                                title: "Pagkakaisa at Pag-aalsa",
                                type: "Completed",
                                count: 12,
                                accuracy: "89%",
                                color: "bg-purple-500",
                            },
                            {
                                title: "Rebolusyong Pilipino",
                                type: "In Progress",
                                count: 7,
                                accuracy: "N/A",
                                color: "bg-orange-500",
                            },
                            {
                                title: "Panahon ng Amerikano",
                                type: "Completed",
                                count: 20,
                                accuracy: "85%",
                                color: "bg-teal-500",
                            },
                            {
                                title: "Pamahalaan at Politika",
                                type: "Completed",
                                count: 16,
                                accuracy: "83%",
                                color: "bg-cyan-500",
                            },
                            {
                                title: "Mga Kasunduan sa Kasaysayan",
                                type: "Completed",
                                count: 11,
                                accuracy: "80%",
                                color: "bg-pink-500",
                            },
                        ].map((activity, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-md flex flex-col hover:shadow-xl transition duration-300 ease-in-out">
                                <div className="flex items-center mb-4">
                                    <div className={`w-3 h-3 rounded-full ${activity.color} mr-3`}></div>
                                    <div className="font-semibold text-sm">{activity.type}</div>
                                </div>
                                <h3 className="text-xl font-bold mb-2 text-darkp">{activity.title}</h3>
                                <p className="text-gray-600 text-sm mb-4">
                                    {activity.count} Questions
                                </p>
                                {activity.accuracy !== "N/A" && (
                                    <div className={`mt-auto text-white text-center py-2 w-full rounded-md ${activity.color} font-bold`}>
                                        {activity.accuracy} accuracy
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentActivityPage;
