import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import logoDarkIcon from '../assets/images/logoDark-icon.png';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleLogin = async () => {
        setError("");

        if (!email) {
            setError("Please enter your email.");
            return;
        }
        if (!password) {
            setError("Please enter your password.");
            return;
        }

        try {
            // Query Firestore for the tbl_teacher collection
            let q = query(
                collection(db, "tbl_teacher"),
                where("Email", "==", email),
                where("Password", "==", password)
            );

            let querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userId = userDoc.id;  // Get unique User ID

                // Save User ID to local storage
                localStorage.setItem('userID', userId);
                localStorage.setItem('role', 'Teacher');

                // Redirect to Teacher's Library
                navigate('/teacher/Library');
                return;
            }

            // If no teacher found, query Firestore for the tbl_student collection
            q = query(
                collection(db, "tbl_students"),
                where("Email", "==", email),
                where("Password", "==", password)
            );

            querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userId = userDoc.id;

                // Save User ID to local storage
                localStorage.setItem('userID', userId);
                localStorage.setItem('role', 'Student');

                // Redirect to Student Dashboard
                navigate('/Student/Dashboard');
            } else {
                setError("Invalid email or password. Please try again.");
            }
        } catch (err) {
            console.error("Error logging in: ", err);
            setError("An error occurred while logging in.");
        }
    };

    return (
        <div className="bg-yellow flex flex-col gap-5 pt-10 items-center justify-center h-screen px-4">
            <div className="bg-white border border-blue rounded-md py-5  w-full max-w-md">
                <div className="flex justify-center gap-8 items-center">

                        <img
                            src={logoDarkIcon}
                            className="w-16 h-16  md:w-24 md:h-24"
                        />
                        <span
                            className="text-3xl md:text-3xl font-bold text-center text-blue cursor-pointer"
                        >
                        KWISAYSAYAN
                        </span>
                </div>

            </div>
            <section className="login border  border-blue flex flex-col gap-6 md:mb-10 bg-white rounded-lg p-6 sm:p-8 md:p-10 w-full max-w-md">
                <h2 className="text-2xl sm:text-3xl font-bold text-blue text-center">Log in and let's start</h2>
                <section className="flex flex-col gap-4">
                    <label htmlFor="email" className="text-lg sm:text-xl  text-left font-semibold text-blue">Email</label>
                    <input
                        placeholder="Enter your email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    />
                    <label htmlFor="password" className="text-lg sm:text-xl text-left font-semibold text-blue">Password</label>
                    <input
                        placeholder="Enter your password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="p-3 sm:p-4 text-base sm:text-lg border border-yellow rounded-lg"
                    />
                    <span
                        className="text-sm sm:text-base font-semibold text-left text-red cursor-pointer"
                        onClick={() => navigate("/forgot-password")}
                    >
                        Forgot Password?
                    </span>

                    <div className="flex gap-2">
                        <span
                            className="text-sm sm:text-base font-semibold text-center text-blue cursor-pointer"
                        >
                        Don't have an account yet? 
                        </span>

                        <span
                            className="text-sm sm:text-base font-semibold text-center text-red cursor-pointer hover:text-blue hover:border hover:border-red rounded-md hover:px-1"
                            onClick={() => navigate("/sign-in")} // Updated to navigate to the sign-in page
                        >
                            SIGN IN
                        </span>
                    </div>
                  
                </section>
                <button
                    onClick={handleLogin}
                    className="h-12 sm:h-14 mt-4 bg-yellow text-blue border-2 border-blue hover:bg-yellow hover:text-red hover:border-red rounded-lg w-full text-lg sm:text-xl font-bold"
                >
                    LOGIN
                </button>

                {error && (
                    <p className="text-red text-sm sm:text-base mt-4 text-center">{error}</p>
                )}
            </section>
        </div>
    );
};

export default Login;